// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    production: false,
    appCurrancy: '╚w¢',
    appName: 'LoudWinner',
    appSlogan: 'Enjoy, Educate and WIN!',
    appSeperator: ' - ',
    appDefaultImage: 'assets/loudwinner/slider1.jpg',
    appLogo: 'assets/loudwinner/slider1.jpg',
    appURI: 'https://loudwinner.com/',
    darkMode: 'LoudWinnerDarkMode',
    showCounter: 'LoudWinnerShowCounter',
    showLettersNumbers: 'LoudWinnerShowShortNumbers',
    // backend: 'http://localhost:8000/api/app/v1/',
    // backendStorage: 'http://localhost:8000/uploads/',
    // backendUrl: 'localhost',
    backend: 'https://loudwinner.com/fr/api/app/v1/',
    backendStorage: 'https://loudwinner.com/fr/uploads/',
    backendUrl: 'loudwinner.com/fr',
    userLangStorage: 'LoudWinnerAppDefaultLang',
    userIdStorage: 'LoudWinnerUserID',
    userIpConfig: 'LoudWinnerAppIpConf',
    userIpAddress: 'LoudWinnerAppIp',
    guestNotesToken: 'LoudWinnerGuestNotesToken',
    userNotesToken: 'LoudWinnerUserNotesToken',
    limitPerRequest: 10,
    authTokenKey: 'LoudWinnerApp',
    userDeviceMode: 'LoudWinnerAppUserDevice',
    goeLocation: 'LoudWinnerGeoLocation',
    PaypalClientID: 'AZz440c13_M5yadH4Qf7ivjDRfcfE868Fe4v0tZLBgBYqqn_0nNGy5GfUwtitR7EkF7RuXoFckG8VTrG', //Tests
    miningSpeed: 'LoudWinnerDefMiningSpeed'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
