import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Master } from 'src/app/core/masters/master.class';

@Component({
    selector: 'app-need-to-login-or-register',
    templateUrl: './need-to-login-or-register.component.html',
    styleUrls: ['./need-to-login-or-register.component.scss'],
})
export class NeedToLoginOrRegisterComponent extends Master implements OnInit {

    @Input() showText: boolean = true;

    @Input() size: 'small' | 'default' | 'large' = 'default';
    @Input() fill: 'clear' | 'outline' | 'solid' = 'solid';
    @Input() color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' = 'primary';
    @Input() expand: 'full' | 'block' = 'full';
    @Input() txt: string = 'LOGIN_OR_REGISTER_BUTTON';

    _transSeciton: string = 'SHARED.';

    constructor(
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
    }

    NavToAuthWithReturnLink(str: string = 'reg'){
        const curURI = this.router.url;
        localStorage.setItem('tempUrlRedirect', curURI);
        if(str == 'reg')
            this.router.navigateByUrl('auth/register?returnTo='+curURI);
        if(str == 'log')
            this.router.navigateByUrl('auth/login?returnTo='+curURI);
    }

}
