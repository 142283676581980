import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
    name: 'lwD'
})
export class LoudwinnerDatePipe implements PipeTransform {

    constructor(
        protected translate: TranslateService
    ){}

    transform(value: unknown, format: string = 'MMM D, YYYY h:mm A'): string {

        moment.locale(this.translate.currentLang);

        if(format == 'tA'){
            return moment(value).fromNow();
        }

        return moment(value).format(format);
    
    }

}
