import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-skeleton-screen',
    templateUrl: './skeleton-screen.component.html',
    styleUrls: ['./skeleton-screen.component.scss'],
})
export class SkeletonScreenComponent implements OnInit {

    slideOpts = {
        // initialSlide: 1,
        speed: 400,
        freeMode: true,
        slidesPerView: 2.2,
        spaceBetween: 0
    };

    slideOpts2 = {
        // initialSlide: 1,
        speed: 400,
        freeMode: true,
        slidesPerView: 1.2,
        spaceBetween: 0
    };

    @Input() type: string;

    @Input() homePage: string[];

    constructor() { }

    ngOnInit() {
        // console.log(this.type)
    }

}
