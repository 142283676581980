import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private http: HttpClient
    ) { }

    updateAccount(data: any): Observable<any>{
        return this.http.put(environment.backend+'profile/updateAccount', data);
    }

    updateProfile(data: any): Observable<any>{
        const settings = {settings: data};
        return this.http.put(environment.backend+'profile/updateProfile', settings);
    }

}
