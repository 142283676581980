import { Injector } from '@angular/core';
 
export class AvenueInjector {
    
    private static injector: Injector;
    
    static setInjector(injector: Injector) {
        AvenueInjector.injector = injector;
    }
    
    static getInjector(): Injector {
        return AvenueInjector.injector;
    }
    
}