import { MasterFormState } from '../../../interfaces/state.masters';
import { User } from '../../../interfaces/user';

export interface AuthState {
    /**
     * Master
     */
    loading: boolean;
    /**
     * Auth State
     */
    isLoggedIn: boolean;
    accessToken: string;
    tokenType: string;
    /**
     * User State
     */
    user: User;
    isNewUser: boolean;
    /**
     * Actions
     */
    authAction: MasterFormState;
    /**
     * Redirect state
     */
    redirectTo: string;
    /**
     * Notifications
     */
    notesCount: number;
    /**
     * Active games
     */
    activeGames: number;
    /**
     * Games Subs
     */
    subs: number[];
}

export const InitAuthState: AuthState = {
     /**
     * Master
     */
    loading: false,
    /**
     * Auth State
     */
    isLoggedIn: false,
    accessToken: undefined,
    tokenType: undefined,
    /**
     * User State
     */
    user: null,
    isNewUser: false,
    /**
     * Actions
     */
    authAction: {
        ActionLoading: false,
        ModelValues: undefined,
        ActionNotice: undefined,
        ModelWasAdded: false,
    },
    /**
     * Redirect to
     */
    redirectTo: null,
    /**
     * Notifications
     */
    notesCount: 0,
    activeGames: 0,
    subs: []
}