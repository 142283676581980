import { Action } from '@ngrx/store';
import { User } from '../../../interfaces/user';
import { ActionNotice } from '../../../interfaces/action.notice';

export enum AuthActionTypes {
    /**
     * Master
     */
    AuthToggleLoader = '[Auth] Toggle Loader',
    /**
     * Basic Auth Actions
     */
    Login = '[Auth] Login User',
    AutoLogin = '[Auth] Auto Login User',
    Logout = '[Auth] Logout User',
    RegisterUser = '[Auth] Register User',
    UserWasLoggedIn = '[Auth] User Was Logged In',
    ResetPassword = '[Auth] Reset Password',
    /**
     * Auth Action Actions
     */
    ToggleAuthActionLoader = '[Auth - Action] Toggle Loader',
    SetActionNotice = '[Auth - Action] Set Action Notice',
    /**
     * Model Update
     */
    UpdateUserAccount = '[Profile - Action] Update Account',
    UpdateUserProfile = '[Profile - Action] Update User Profile',
    UserWasUpdated = '[Profile - Action] User Was Updated',
    UpdateUserPassword = '[Profile - Action] Change User Password',

    /**
     * Notifications Register
     */
    RegisterUserNotificationToken = '[Auth - Notifications] Register User Notifications Token',

    /**
     * Listeners
     */
    UserObjectChanged = '[Auth - Listener] User Object Changed',

    /************
     * Subs
     */
    ToggleSubForGame = '[Auth - Subs] Toggle Sub For Game' 

}

/************
 * Subs
 */
export class ToggleSubForGame implements Action {
    readonly type = AuthActionTypes.ToggleSubForGame;
    constructor(public payload: boolean, public gameId: number){}
}

/**
 * Listerners
 */
export class UserObjectChanged implements Action {
    readonly type = AuthActionTypes.UserObjectChanged;
    constructor(public payload: {userObj: User, notesCount: number, activeGames: number, subs: number[]}){}
}

export class AuthToggleLoader implements Action {
    readonly type = AuthActionTypes.AuthToggleLoader;
    constructor(public payload: boolean){}
}

export class RegisterUserNotificationToken implements Action {
    readonly type = AuthActionTypes.RegisterUserNotificationToken;
    constructor(public payload: string){}
}

/**
 * Basic Auth Actions Start
 */

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: {email: string, password: string}){}
}

export class ResetPassword implements Action {
    readonly type = AuthActionTypes.ResetPassword;
    constructor(public payload: {email: string}){}
}

export class AutoLogin implements Action {
    readonly type = AuthActionTypes.AutoLogin;
    public constructor(public initApp?: boolean){}
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class RegisterUser implements Action {
    readonly type = AuthActionTypes.RegisterUser;
    constructor(public payload : {
        email: string,
        password: string,
        name: string,
    }){}
}

export class UserWasLoggedIn implements Action {
    readonly type = AuthActionTypes.UserWasLoggedIn;
    constructor(public payload: {
        accessToken: string;
        tokenType: string;
        user: User;
        notesCount: number;
        activeGames: number;
        subs: number[]
    }, public isNewUser: boolean = false){}
}

/**
 * Basic Auth Actions Finish
 */

/**
 * Auth Action Actions Start
 */

export class ToggleAuthActionLoader implements Action {
    readonly type = AuthActionTypes.ToggleAuthActionLoader;
    constructor(public payload: boolean){}
}

export class SetActionNotice implements Action {
    readonly type = AuthActionTypes.SetActionNotice;
    constructor(public payload: ActionNotice){}
}

/**
 * Auth Action Actions Finish
 */

/**
 * Profile Action Start
 */

export class UpdateUserAccount implements Action {
    readonly type = AuthActionTypes.UpdateUserAccount;
    constructor (public payload: any){}
}

export class UpdateUserProfile implements Action {
    readonly type = AuthActionTypes.UpdateUserProfile;
    constructor (public payload: any){}
}

export class UserWasUpdated implements Action {
    readonly type = AuthActionTypes.UserWasUpdated;
    constructor(public payload: User){}
}

export class UpdateUserPassword implements Action {
    readonly type = AuthActionTypes.UpdateUserPassword;
    constructor(public payload: any){}
}

/**
 * Profile Actions Finish
 */


export type AuthActions = 
//Master
AuthToggleLoader | 
RegisterUserNotificationToken |
//Auth Basics
Login | AutoLogin | Logout | RegisterUser | UserWasLoggedIn | ResetPassword |
//Action Actions
ToggleAuthActionLoader | SetActionNotice |
//Profile Action
UpdateUserAccount | UpdateUserProfile | UserWasUpdated | UpdateUserPassword | 
//Listener
UserObjectChanged |
//Toggle 
ToggleSubForGame; 