import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/base';
import { getErrorHandler } from '../crud/helpers';
import { ActionNotice } from '../interfaces/action.notice';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CrudErrorHandlerService {

    private _tranSection: string = 'CRUD_ERRORS.';

    private error: any;
    private NoticeAction: any;
    private msg: string;
    private data: any;

    constructor(
        private _translate: TranslateService,
        private _router: Router,
        private store: Store<AppState>,
        private _toast: ToastController 
    ) { }

    public _handle(err, noticeAction: any = null, data = null, noticeInterface: ActionNotice = null){
        this.error = err;
        this.NoticeAction = noticeAction;
        this.data = data;
        let notice;
        this._handleStatus()
        if(noticeInterface){
            notice = noticeInterface;
            notice = {
                ...notice,
                msg: this.msg,
                class: 'danger'
            }
        }else{
            notice = {
                msg: this.msg,
                class: 'danger'
            };
        }  
        this.presentToast(this.msg, err.error.data?.toast_obj ? err.error.data.toast_obj : null);
        if(noticeAction)
            this.store.dispatch(new this.NoticeAction(notice));
    }

    async presentToast(msg: string, toastObj: any = null) {
        const toast = await this._toast.create(toastObj ?? {
            message: msg,
            duration: 7500,
            color: 'danger',
            buttons: [
                {
                    side: 'end',
                    icon: 'close',
                },  
            ]
        });
        
        toast.present();
    }

    _400(data: any = null): string {
        if(data.error.show_error){
            if(data.error.data.msg.translate){
                return this.getTran(data.error.data.msg.text)
            }else{
                return data.error.data.msg.text;
            }
        }
        return this.getTran('BAD_REQUEST');
    }

    _401(): string {
        return this.getTran('NEED_TO_LOGIN');
    }

    _403(): string {
        return this.getTran('NOT_AUTH');
    }
    
    _404(): string{
        this._router.navigateByUrl('404', {replaceUrl: true});
        return this.getTran('NOT_FOUND');;
    }

    _422(err, data: any): string{
        let msg: string[] = [];
        for(let key of Object.keys(data)){
            if(err.error.errors[key] && err.error.errors[key].length > 0){
                for(let i=0 ; i< err.error.errors[key].length ; i++){ //copy the errors of this specific key , errors taht were recieved from the backend
                    msg.push(this.getTran(err.error.errors[key][i]));
                }
            }
        }
        return msg.join('<br>');
    }

    _500(): string{
        return this.getTran('SERVER_ERROR');
    }

    private _handleStatus(){

        const handler = getErrorHandler(this.error);
        switch(handler){
            
            case(400):
                this.msg = this._400(this.error);
                break;

            case(401):
                this.msg = this._401();
                break;

            case(403):
                this.msg = this._403();
                break;
            
            case(404):
                this.msg = this._404();
                break;

            case(422):
                this.msg = this._422(this.error, this.data);
                break;

            case(500):
                this.msg = this._500();
                break;

            default:
                this.msg = this._400();

        }
    }
    

    private getTran(str: string): string{
        return this._translate.instant(this._tranSection+str);
    }

}
