import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core/store/base';
import { ___BlocksNumbersBought } from 'src/app/core/store/base/_selectors/base.selectors';

@Injectable({
    providedIn: 'root'
})
export class BlocksSharedService {

    public blocksBought$: Observable<string[]>;

    constructor(
        private _store: Store<AppState>
    ) {
        this.initListener();
    }

    initListener(){
        this.blocksBought$ = this._store.pipe(
            select(___BlocksNumbersBought)
        );
    }

}
