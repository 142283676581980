import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

/*******************************
 * OUTSOURCING Modules
 */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

 /*******************************
  * LoudWinner Module
  */
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers, metaReducers } from './core/store/base';
import { BaseReducer } from './core/store/base/_reducer/base.reducer';
import { AuthReducer } from './core/store/auth/_reducers/auth.reducers';
import { BaseEffects } from './core/store/base/_effects/base.effects';
import { AuthEffects } from './core/store/auth/_effects/auth.effects';
import { AuthService } from './core/store/auth/_services/auth.service';
import { LoudwinnerApiService } from './core/services/loudwinner-api.service';
import { SeoService } from './core/services/seo.service';
import { InterceptService } from './core/services/intercept.service';
import { ListenersService } from './core/services/listeners.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule, 
        IonicModule.forRoot(),
        HammerModule, 
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            // missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            },
        }),
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([]),
        StoreModule.forFeature('base', BaseReducer),
        StoreModule.forFeature('auth', AuthReducer),
        EffectsModule.forFeature([BaseEffects, AuthEffects]),
        StoreDevtoolsModule.instrument(),
        SharedModule,
    ],
    providers: [
        { 
            provide: RouteReuseStrategy, 
            useClass: IonicRouteStrategy 
        },
        InterceptService,
        {
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
        },
        AuthService,
        LoudwinnerApiService,
        SeoService,
        ListenersService,
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule {}
