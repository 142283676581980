import { Category } from "src/app/core/interfaces/category";
import { Game } from "src/app/core/interfaces/game";
import { Lottery } from "src/app/core/interfaces/lottery";
import { environment } from "src/environments/environment";

export interface BaseState {

    loading: boolean;

    categoriesWasLoaded: boolean;
    categories: Category[];

    /**
     * App settings
     */
    darkMode: boolean;
    showCounter: boolean;
    lettersNumber: boolean;

    /**
     * Config
     */
    paymentMethods: any;
    paymentPackages: any;

    hideTabs: boolean;

    /**
     * Shared Actions
     */
    blocksNumbersBought: string[];

    toggleMultiBuyModal: string;

    ignoreNotesFrom: string[]; 

    blockFormsWereOpened: number[];

    //Tem store (used to save clicked data (nav to offer / category ...))
    temStoreType: 'game' | 'lottery';
    temStoreData: Game | Lottery;

    counterUnsub: string;

    /**
     * Real Time Listener
     */
    listenTo: {lis: boolean, event: string, sub: string, fun: string};

    /**********
     * Mining
     */
    isMining: boolean;
    miningLotteryObject: Lottery;
    miningLotteryId: number;
    miningLotteryTime: number;
    miningNeededFast: number;
    miningOptions: any;
    miningRes: any;
    
}

export const InitBaseState: BaseState = {

    loading: false,

    categoriesWasLoaded: false,
    categories: [],

    darkMode: (localStorage.getItem(environment.darkMode) && localStorage.getItem(environment.darkMode) == 'true') ? true : false,
    showCounter: (localStorage.getItem(environment.showCounter) && localStorage.getItem(environment.showCounter) == 'false') ? false : true,
    lettersNumber: (localStorage.getItem(environment.showLettersNumbers) && localStorage.getItem(environment.showLettersNumbers) == 'true') ? true : false,

    paymentMethods: null,
    paymentPackages: null,

    hideTabs: undefined,

    blocksNumbersBought: [],

    toggleMultiBuyModal: undefined,

    ignoreNotesFrom: [],

    blockFormsWereOpened: [],

    //Tem store (used to save clicked data (nav to offer / category ...))
    temStoreType: undefined,
    temStoreData: undefined,

    counterUnsub: null,

    /**********
     * Mining
     */
    isMining: false,
    miningLotteryObject: undefined,
    miningLotteryId: undefined,
    miningLotteryTime: 0,
    miningNeededFast: null,
    miningOptions: undefined,
    miningRes: undefined,

    /**
     * Real Time Listener
     */
    listenTo: undefined,

}