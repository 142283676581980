import { createSelector } from '@ngrx/store';

import { BaseState } from '../_state/base.state';

export const SelectBaseState = state => state.base;

export const ___Loading = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.loading
);

export const ___DarkMode = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.darkMode
)

export const ___HideTabs = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.hideTabs
)

export const ___ShowCounter = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.showCounter
)

export const ___NumberLettersDisplay = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.lettersNumber
)

export const ___PaymentMethods = createSelector(
    SelectBaseState,
    (baseState: BaseState) => JSON.parse(baseState.paymentMethods)
)

export const ___PaymentPackages = createSelector(
    SelectBaseState,
    (baseState: BaseState) => JSON.parse(baseState.paymentPackages)
)

export const ___CategoriesWasLoaded = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.categoriesWasLoaded
)

export const ___CategoriesData = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.categories
)

export const ___BlocksNumbersBought = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.blocksNumbersBought
)

export const ___ToggleMultipleMode = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.toggleMultiBuyModal
)

export const ___IgnoreNotesOf = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.ignoreNotesFrom
)

export const ___TemStoreData = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.temStoreData
);

export const ___TemStoreType = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.temStoreType
);

export const ___BlockFormsWereOpen = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.blockFormsWereOpened
);

export const ___CounterUnsub = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.counterUnsub
);

/*********
 * Listener
 */

export const ___LoudWinnerListener = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.listenTo
);

/*********
 * Mining
 */

export const ___LoudWinnerIsMining = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.isMining
);

export const ___LoudWinnerMiningLottery = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.miningLotteryObject
);

export const ___LoudWinnerMiningLotteryId = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.miningLotteryId
);

export const ___LoudWinnerMiningTime = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.miningLotteryTime
);

export const ___LoudWinnerMiningRes = createSelector(
    SelectBaseState,
    (baseState: BaseState) => baseState.miningRes
);