import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'gameTran'
})
export class GameTranslatePipe implements PipeTransform {

    private _titleSection: string = 'SYSTEM.GAMES.';
    private _descSection: string = 'SYSTEM.GAMES.';

    constructor(private translateService: TranslateService){}

    transform(value: any, gameType: 'm', type: string = 't'): string {
        this.zeroData();
        if(gameType == 'm'){
            this._titleSection = this._titleSection + 'MASS_GAMES.';
            this._descSection = this._descSection + 'MASS_GAMES.';
        }

        if(type === 't'){
            return this.translateService.instant(this._titleSection+value+'.TITLE');
        }
        if(type === 'd'){
            return this.translateService.instant(this._descSection+value+'.DESC');
        }
    }

    private zeroData(){
        this._titleSection = 'SYSTEM.GAMES.';
        this._descSection = 'SYSTEM.GAMES.';
    }

}
