import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ActionHandlerInterface, ActionsHandlerInterface, MasterResponseInterface } from '../interfaces/responses';
import { AutoLogin } from '../store/auth/_actions/auth.actions';
import { AppState } from '../store/base';
import { CrudErrorHandlerService } from './crud-error-handler.service';
import { ResponseAlertService } from './response-alert.service';

@Injectable({
    providedIn: 'root'
})
export class LoudwinnerApiService {

    constructor(
        private store: Store<AppState>,
        private _alert: ResponseAlertService,
        private _router: Router,
        private _nav: NavController,
        private _errors: CrudErrorHandlerService
    ){}

    /**
     * @description Handle all request as should be handeled
     * @param req Observable<HTTP>
     * @param actions Actions (NGRX)
     */
    public _handle(req: Observable<any>, actions: ActionsHandlerInterface, formData?: any){
        if(actions && actions.before){
            this.dispatchActions(actions.before);
        }
        req.pipe(
            take(1),
            finalize(
                () => {
                    if(actions && actions.after){
                        this.dispatchActions(actions.after);
                    }
                }
            )
        ).subscribe(
            (res: MasterResponseInterface) => {
                this.responseHandling(res, actions?.response ? actions.response : null);
            }, 
            (err) => {
                this.errorHandling(err, actions?.error ? actions.error : null, formData);
            }
        )
    }

    public responseHandling(response: MasterResponseInterface, actions?: ActionHandlerInterface[]){
        if(actions && actions.length > 0){
            this.dispatchActionsWithResponse(actions, response);
        }
        if(response.show_alert){
            this._alert._hadleAlerts(response.alert)
        }
        if(response.re_auth){
            setTimeout(() => {
                this.store.dispatch(new AutoLogin());
            }, 1200)
        }
        if(response.redirect && response.redirect != null){
            if(response.redirect === 'back'){
                this._nav.back();
            }else{
                this._router.navigateByUrl(response.redirect);
            }
        }
    }

    public errorHandling(error, actions?: ActionHandlerInterface[], formData?: any){        
        if(actions && actions.length > 0){
            this.dispatchActionsWithResponse(actions, error.error);
        }
        if(error.error.show_alert){
            this._alert._hadleAlerts(error.error.alert);
        }
        if(!(error.error.show_alert) && !actions){
            this._errors._handle(error, null, formData);
        }
        if(error.error.redirect && error.error.redirect != null){
            if(error.redirect === 'back'){
                this._nav.back();
            }else{
                this._router.navigateByUrl(error.redirect, {
                    replaceUrl: true
                });
            }
        }
    }

    private dispatchActionsWithResponse(actions: ActionHandlerInterface[], res: MasterResponseInterface){
        if(actions && actions.length > 0){
            actions.forEach((it: ActionHandlerInterface) => {

                let data: any = res;

                if(it.marker){
                    it.marker.forEach((str: string, index: number) => {
                        data = data[str];
                        if(index == (it.marker.length - 1) && it.withFun){
                            data = it.function(data);
                        }
                    })
                }else{
                    data = it.data
                }
                
                this.store.dispatch(new it.action(data ?? null));
                
            })
        }
    }

    private dispatchActions(actions: ActionHandlerInterface[]){
        if(actions && actions.length > 0){
            actions.forEach((it: ActionHandlerInterface) => {
                if(it.loop){
                    it.data.forEach((elm) => {
                        this.store.dispatch(new it.action(elm));
                    })
                }else{
                    this.store.dispatch(new it.action(it.data));
                }
            })
        }
    }

}