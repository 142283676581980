import { Config } from '@ionic/angular';
import { AvenueInjector } from './avenue.injector';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/base';
import { Subject, Observable } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ___AuthUserLoggedIn, ___AuthUser } from '../store/auth/_selectors/auth.selectors';
import { User } from '../interfaces/user';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeoService } from '../services/seo.service';

/**
 * Master class gives you all master & core features of the app & the storage.
 * 
 * @param public ios: boolean; Return if device is ios or not.
 * @param public pageTitle: string; Component Title
 * @param protected conf: Config; Can be used to config settings.
 * @param protected store: Store; Store to use everywhere.
 * @param protected translate: TranslateService; Translate service.
 * @param public IsLoggedIn$: User auth subscription
 * 
 * @method protected getUserObj(): User. Get the user as object.
 * @method protected setTitle(str). Set the page title.
 * 
 * Master class has _onDestory Subject that will be emit when component is destroyed.
 * 
 */

export class Master implements OnDestroy {

    public ios: boolean;
    public pageTitle: string = 'TITLES.';
    public metaImage: string = environment.appLogo;
    public appCurrancy: string = environment.appCurrancy;
    protected conf: Config;
    protected store: Store<AppState>;
    protected translate: TranslateService;
    protected seo: SeoService;

    public basicInputLabelLayout = 'floating';

    protected colors = [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
        'light',
        'medium',
        'dark'
    ];

    public pickedColors = [];

    // public defaultHref: string; //Just for angular build

    public IsLoggedIn$: Observable<boolean>;

    _onDestroy: Subject<void> = new Subject();

    constructor(){
        const avenue = AvenueInjector.getInjector();
        this.conf = avenue.get(Config);
        this.store = avenue.get(Store);
        this.translate = avenue.get(TranslateService);
        this.ios = (this.conf.get('mode') === 'ios');
        this.seo = avenue.get(SeoService);
        this.IsLoggedIn$ = this.store.pipe(
            select(___AuthUserLoggedIn),
            takeUntil(this._onDestroy)
        );
    }

    public getUserObj(): User{
        let user: User;
        this.store.pipe(select(___AuthUser), takeUntil(this._onDestroy)).subscribe(
            (res) => {
                user = res;
            }
        )
        return user;
    }

    public getImage(str: string): string
    {
        return str.includes('http') ? str : environment.backendStorage+str;
    }

    protected setTitle(str: string) {
        this.pageTitle = this.pageTitle+str;
    }

    setSeoDefault(){
        this.seo.setMetaTags(environment.appSlogan, this.translate.instant('SEO.DESC'), this.metaImage, environment.appURI);
    }

    ionViewDidLeave(){
        this._onDestroy.next();
    }

    ngOnDestroy(){
        this._onDestroy.next();
    }

    protected pickRandomColorStyle(num: number = 1){
        for(let i = 0; i < num; i++){
            this.pickedColors.push(this.colors[Math.floor(Math.random() * this.colors.length)]);
        }
    }

}