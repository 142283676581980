import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Login, AuthActionTypes, AuthToggleLoader, UserWasLoggedIn, AutoLogin, Logout , RegisterUser, ToggleAuthActionLoader, UpdateUserAccount, UpdateUserProfile, UpdateUserPassword, UserWasUpdated, ResetPassword, RegisterUserNotificationToken, ToggleSubForGame,} from '../_actions/auth.actions';
import { tap, finalize, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable, defer, of } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { AppState } from '../../base';
import { Router } from '@angular/router';
import { ProfileService } from '../_services/profile.service';
import { ActionsHandlerInterface } from '../../../interfaces/responses';
import { LoudwinnerApiService } from 'src/app/core/services/loudwinner-api.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';

@Injectable()
export class AuthEffects {

    @Effect({dispatch: false})
    ToggleSubForGame$ = this.actions$.pipe(
        ofType<ToggleSubForGame>(AuthActionTypes.ToggleSubForGame),
        tap(
            (action: ToggleSubForGame) => {
                const actionsHandler: ActionsHandlerInterface = {
                    before: [
                        {
                            action: AuthToggleLoader,
                            definer: 'loader',
                            data: true
                        },
                    ],
                    // after: [
                    //     {
                    //         action: AuthToggleLoader,
                    //         definer: 'loader',
                    //         data: false
                    //     }
                    // ]
                };
        
                this._loudwinner._handle(
                    this._ser.toggleSub(action.payload, action.gameId),
                    actionsHandler,
                );
            }
        )
    );

    @Effect({dispatch: false})
    RegisterUserNotificationToken$ = this.actions$.pipe(
        ofType<RegisterUserNotificationToken>(AuthActionTypes.RegisterUserNotificationToken),
        tap(
            (action: RegisterUserNotificationToken) => {
                this._ser.registerUserNotificationToken(action.payload);
            }
        )
    );

    /**
     * Auth Effects Start
     */

    @Effect({dispatch: false})
    loginAction$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(
            (action) => {
                this.login(action.payload);
            }
        )
    );

    @Effect({dispatch: false})
    resetPasswordAction$ = this.actions$.pipe(
        ofType<ResetPassword>(AuthActionTypes.ResetPassword),
        tap(
            (action) => {
                this.resetPassword(action.payload);
            }
        )
    );

    @Effect({dispatch:false})
    registerUser$ = this.actions$.pipe(
        ofType<RegisterUser>(AuthActionTypes.RegisterUser),
        tap(
            (action) => {
                if(action){
                    this.register(action.payload);
                }
            }
        )
    );

    @Effect({dispatch: false})
    logoutUser$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(
            () => {
                this._ser.logout();
                localStorage.removeItem(environment.authTokenKey);
                this._router.navigateByUrl('auth');
                localStorage.removeItem('tempUrlRedirect');
            }
        )
    );

    @Effect({dispatch: false})
    autoLogin$ = this.actions$.pipe(
        ofType<AutoLogin>(AuthActionTypes.AutoLogin),
        tap(
            (action: AutoLogin) => {
                this.refreshToken(action.initApp);
            }
        )
    );

    /**
     * Auth Effects Finish
     */
    

    /**
     * Profile Effects Start
     */
    
    @Effect({dispatch: false})
    UpdateAccount$ = this.actions$.pipe(
        ofType<UpdateUserAccount>(AuthActionTypes.UpdateUserAccount),
        tap(
            (action: UpdateUserAccount) => {
                this.updateAccount(action.payload);
            }
        )
    );

    @Effect({dispatch: false})
    UpdateProfile$ = this.actions$.pipe(
        ofType<UpdateUserProfile>(AuthActionTypes.UpdateUserProfile),
        tap(
            (action: UpdateUserProfile) => {
                this.updateProfile(action.payload);
            }
        )
    );

    @Effect({dispatch: false})
    UpdateUserPassword$ = this.actions$.pipe(
        ofType<UpdateUserPassword>(AuthActionTypes.UpdateUserPassword),
        tap(
            (action: UpdateUserPassword) => {
               this.updatePassword(action.payload) 
            } 
        )
    );

    /**
     * Profile Effects Finish
     */

    /**
     * Init
     */
    @Effect()
    init$: Observable<Action> = defer(() => {
        const token = localStorage.getItem(environment.authTokenKey);
        let acOb$ = of({type: 'NO_ACTION'})
        if(token){
            console.log('try to login');
            
            acOb$ = of(new AutoLogin(true)); 
        }
        return acOb$;
    });

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private _ser: AuthService,
        private _router: Router,
        private _profile: ProfileService,
        private _loudwinner: LoudwinnerApiService,
        private _notifications: NotificationsService,
    ){
        
    }

    resetPassword(data: {email: string}){

        const actionsHandler: ActionsHandlerInterface = {
            before: [
                {
                    action: AuthToggleLoader,
                    definer: 'loader',
                    data: true
                },
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: true
                }
            ],
            after: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: false
                },
                {
                    action: AuthToggleLoader,
                    definer: 'loader',
                    data: false
                }
            ]
        };

        this._loudwinner._handle(
            this._ser.resetPassword(data),
            actionsHandler,
            data
        );

    }

    updatePassword(data: any) {

        const actionsHandler: ActionsHandlerInterface = {
            before: [
                {
                    action: AuthToggleLoader,
                    definer: 'loader',
                    data: true
                },
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: true
                }
            ],
            after: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: false
                },
                {
                    action: AuthToggleLoader,
                    definer: 'loader',
                    data: false
                }
            ],
            response: [
                {
                    action: UserWasUpdated,
                    definer: 'updater',
                    marker: ['data', 'object']
                }
            ]
        };

        this._loudwinner._handle(
            this._ser.changePassword(data),
            actionsHandler,
            data
        );

    }

    updateAccount(data: any) {  

        const actionsHandler: ActionsHandlerInterface = {
            before: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: true
                }
            ],
            after: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: false
                },
            ],
            response: [
                {
                    action: UserWasUpdated,
                    definer: 'updater',
                    marker: ['data', 'object']
                }
            ]
        };

        this._loudwinner._handle(
            this._profile.updateAccount(data),
            actionsHandler,
            data
        );
        
    }

    updateProfile(data: any){

        const actionsHandler: ActionsHandlerInterface = {
            before: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: true
                }
            ],
            after: [
                {
                    action: ToggleAuthActionLoader,
                    definer: 'loader',
                    data: false
                },
            ],
            response: [
                {
                    action: UserWasUpdated,
                    definer: 'updater',
                    marker: ['data', 'object']
                }
            ]
        };

        this._loudwinner._handle(
            this._profile.updateProfile(data),
            actionsHandler,
            data
        );

    }

    login(data: {email: string, password: string}){

        this.store.dispatch(new ToggleAuthActionLoader(true));

        this._ser.login(data).pipe(
            take(1),
            finalize(() => {
                this.store.dispatch(new ToggleAuthActionLoader(false));
            })
        ).subscribe(
            (response) => {
                this._loudwinner.responseHandling(response);
                const res = response.data.data;
                this._handleAuth(res, false, 'app');
            },
            (err) => {
                this._loudwinner.errorHandling(err);
            }
        );

    }

    register(data: {email: string, password: string , name:string}){

        this.store.dispatch(new ToggleAuthActionLoader(true));

        this._ser.register(data).pipe(
            take(1),
            finalize(() => {
                this.store.dispatch(new ToggleAuthActionLoader(false));
            })
        ).subscribe(
            (response) => {
                this._loudwinner.responseHandling(response);
                const res = response.data.data;
                this._handleAuth(res, true, 'app', true);
            },
            (err) => {
                this._loudwinner.errorHandling(err, null, data);
            }
        );

    }

    refreshToken(initApp?: boolean){

        this.store.dispatch(new AuthToggleLoader(true));

        this._ser.refreshToken().pipe(
            take(1),
            finalize(() => {
                this.store.dispatch(new AuthToggleLoader(false));
            })
        ).subscribe(
            (response) => {
                this._loudwinner.responseHandling(response);
                const res = response.data.data;
                this._handleAuth(res);
            },
            (err) => {
                this._loudwinner.errorHandling(err);
            }
        );

    }

    _handleAuth(res, isNewUser: boolean = false, navigate: string = null, askForNotes: boolean = false) {
        this.store.dispatch(new UserWasLoggedIn(res, isNewUser));
        localStorage.setItem(environment.authTokenKey, res.accessToken);
        localStorage.setItem(environment.userIdStorage, res.user.id);
        if(!res.user.notes_token){
            if(localStorage.getItem(environment.guestNotesToken)){
                this.store.dispatch(new RegisterUserNotificationToken(localStorage.getItem(environment.guestNotesToken)));
                console.log('neeed notifications');
            }else{
                if(askForNotes){
                    this._notifications.initPushNotifications();
                }
            }
        }
        if(res.user.notes_token){
            if(!localStorage.getItem(environment.userNotesToken)){
                localStorage.setItem(environment.userNotesToken, res.user.notes_token);
                console.log('neeed notifications');
            }
        }
        
        if(localStorage.getItem('tempUrlRedirect')){

            setTimeout(() => {
                localStorage.removeItem('tempUrlRedirect');
            }, 3500);
            setTimeout(() => {
                this._router.navigateByUrl(localStorage.getItem('tempUrlRedirect'));
                // localStorage.removeItem('tempUrlRedirect');
            }, 500);
            

        }
        if(navigate && !(localStorage.getItem('tempUrlRedirect'))){
            this._router.navigateByUrl(navigate);
        }

    }

}
