import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient
    ) { }


    toggleSub(checked: boolean, gameId: number): Observable<any>
    {
        return this.http.put(environment.backend+'auth/toggleSub', {checked: checked, game_id: gameId});
    }

    registerUserNotificationToken(token: string){
        this.http.put(environment.backend+'auth/registerUserNotificationToken', {token: token}).subscribe();
    }

    login(data: {email: string, password: string}): Observable<any>{
        return this.http.post(environment.backend+'auth/login', data);
    }

    register(data: {email: string, password: string, name: string}): Observable<any>{
        return this.http.post(environment.backend+'auth/register', data);
    }

    refreshToken(): Observable<any>{
        return this.http.get(environment.backend+'auth/login');
    }

    changePassword(data: any){
        return this.http.post(environment.backend+'auth/changePassword', data);
    }

    resetPassword(data: {email: string}){
        return this.http.post(environment.backend+'auth/resetPassword', data);
    }

    logout(){
        this.http.get(environment.backend+'auth/logout').subscribe(
            (res) => {
                console.log(res);
            }
        );
    }
    
}
