import { Component, Input, OnInit } from '@angular/core';
import { Game } from 'src/app/core/interfaces/game';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-game-card',
    templateUrl: './game-card.component.html',
    styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent implements OnInit {

    @Input() game: Game;
    @Input() counterDefiner: string = 'home';

    showClosingSoon: boolean = false;

    constructor() { }

    ngOnInit() {
        this.getTimeDiff();
    }

    getImage(str: string): string
    {
        return str.includes('http') ? str : environment.backendStorage+str;
    }

    getTimeDiff(){
        if(this.game.type == 'lottery'){
            var showSoon = moment().add(6, 'hours');
            var announce = moment(this.game.showing_data.time);
            if(showSoon > announce){
                this.showClosingSoon = true;
            }
        }
    }

}
