import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private APP_NAME: string = environment.appName;
    private APP_SLOGAN: string = environment.appSlogan;
    private SEPERATOR: string = environment.appSeperator;
    private APP_DEFAULT_URL: string = environment.appURI;

    constructor(private _meta: Meta, private _title: Title, private _tran: TranslateService){
        this._title.setTitle(this.APP_NAME + this.SEPERATOR + this.APP_SLOGAN)
        this._meta.addTag({property: 'og:description', content: this._tran.instant('SEO.DESC')});
        this._meta.addTag({property: 'og:image', content: environment.appDefaultImage});
        this._meta.addTag({property: 'og:url', content: (this.APP_DEFAULT_URL)});
    }

    setMetaTags(
        title: string, 
        description: string, 
        image: string, 
        url: string, 
        twImageAlt?: string, 
        fbAppId?: string, 
        twCard?: string, 
        siteName?: string,
        twitterSite?: string,
    ){
        this.setTitle(title);
        this._meta.updateTag({property: 'og:title', content: this._tran.instant('APP_NAME') + this.SEPERATOR + title});
        this._meta.updateTag({property: 'og:description', content: description});
        this._meta.updateTag({property: 'og:image', content: image});
        this._meta.updateTag({property: 'og:url', content: (this.APP_DEFAULT_URL + (url))});
        if(twCard != null)
            this._meta.updateTag({name: 'twitter:card', content: twCard});
        if(twImageAlt != null)
            this._meta.updateTag({name: 'twitter:image:alt', content: twImageAlt});
        if(fbAppId != null)
            this._meta.updateTag({property: 'fb:app_id', content: fbAppId});
        if(siteName != null)
            this._meta.updateTag({property: 'og:site_name', content: siteName});
        if(twitterSite != null)
            this._meta.updateTag({name: 'twitter:site', content: twitterSite});
    }

    setTitle(str: string){
        this._title.setTitle(this._tran.instant('APP_NAME')+ this.SEPERATOR + str);
    }

    setDescription(str: string) {
        this._meta.updateTag({name: 'description', content: this._tran.instant('APP_NAME')+this.SEPERATOR+str })
    }

}
