import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Block } from 'src/app/core/interfaces/block';
import { Game } from 'src/app/core/interfaces/game';
import { Master } from 'src/app/core/masters/master.class';

@Component({
    selector: 'app-block-form-number-card',
    templateUrl: './block-form-number-card.component.html',
    styleUrls: ['./block-form-number-card.component.scss'],
})
export class BlockFormNumberCardComponent extends Master implements OnInit {

    @Input() number: number;
    @Input() game: Game;
    @Input() block: Block;

    @Input() multiSelectMode: Observable<boolean>;
    @Input() selectedItemChanged: Observable<number[]>;
    @Input() currentChooseModeIsMulti: boolean = false; 
    @Input() withBlockNumber: boolean = false; 
    

    @Input() actionLoadingSelector: MemoizedSelector<any, boolean, DefaultProjectorFn<boolean>>;
    @Input() acionRefSelector: MemoizedSelector<any, boolean, DefaultProjectorFn<boolean>>;

    @Input() BuyAction: any;

    @Input() displayOnly: boolean = false;

    actionLoading$: Observable<boolean>;
    actionRef$: Observable<any>;

    multiSelectModeRes: boolean = false;
    disableItem: boolean = false;

    constructor(
        private _alert: AlertController,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.multiSelectModeRes = this.currentChooseModeIsMulti;
        if(!this.displayOnly){
            if(this.multiSelectMode){
                this.multiSelectMode.pipe(
                    takeUntil(this._onDestroy)
                ).subscribe((res) => {
                    this.multiSelectModeRes = res;
                });
            }  
            if(this.selectedItemChanged){
                this.selectedItemChanged.pipe(
                    takeUntil(this._onDestroy)
                ).subscribe((res) => {
                    if(res.includes(this.number)){
                        this.disableItem = true;
                    }else{
                        this.disableItem = false;
                    }
                })
            }      
            this.actionLoading$ = this.store.pipe(
                select(this.actionLoadingSelector),
                takeUntil(this._onDestroy),
            );
            this.actionRef$ = this.store.pipe(
                select(this.acionRefSelector),
                takeUntil(this._onDestroy),
            );
        }
        this.pickRandomColorStyle(2);
    }

    handle() {
        if(this.multiSelectModeRes || this.displayOnly)
            return;

        this.IsLoggedIn$.pipe(
            take(1)
        ).subscribe(
            (res) => {
                if(!res){
                    this.getNeedToLoginAlert();
                }
                if(res){
                    const user = this.getUserObj();
                    if(user.wallet.balance >= this.game.price){
                        this.getSaveOrOpenAlert();
                    }else{
                        this.getToChargeAlert();
                    }
                }
            }
        )
    }

    async getSaveOrOpenAlert() {
        const alert = this._alert.create({
            header: this.translate.instant('ALERTS.AUTH.HEADER', {number: this.number}),
            subHeader: this.translate.instant('ALERTS.BUY.SUB', {price: this.game.price}),
            message: this.translate.instant('ALERTS.BUY.MSG', {price: this.game.price}),
            backdropDismiss: true,
            translucent: true,
            buttons: [
                {
                    text: this.translate.instant('ALERTS.BUY.OPEN_NOW'),
                    cssClass: 'success',
                    role: 'open'
                },
                {
                    text: this.translate.instant('ALERTS.BUY.OPEN_LATER'),
                    cssClass: 'success',
                    role: 'buy'
                },
            ],
        }); 
        (await alert).present();
        const { role } =  await (await alert).onWillDismiss();
        if(role === 'buy'){
            this.store.dispatch(new this.BuyAction(this.block, this.number));
        }
        if(role === 'open'){
            this.store.dispatch(new this.BuyAction(this.block, this.number, true));
        }
    }

    async getToChargeAlert(){
        const alert = this._alert.create({
            header: this.translate.instant('ALERTS.AUTH.HEADER', {number: this.number}),
            subHeader: this.translate.instant('ALERTS.CHARGE.MOTIVATE'),
            message: this.translate.instant('ALERTS.CHARGE.MSG', {balance: (this.getUserObj().wallet.balance)}),
            backdropDismiss: true,
            translucent: true,
            buttons: [
                {
                    text: 'Charge my wallet now',
                    cssClass: '',
                    handler: () => {
                        const storeItem = {
                            block: this.block,
                            number: this.number
                        };
                        localStorage.setItem('chargeWalletStorage', JSON.stringify(storeItem));
                        // console.log(this.router.url);
                        localStorage.setItem('tempUrlRedirect', this.router.url);
                        this.router.navigateByUrl('app/profile/wallet/charge-wallet?returnTo='+this.router.url);
                        return true;
                    },
                    role: 'dismiss'
                },
            ],
        }); 
        (await alert).present();
        const { role } =  await (await alert).onWillDismiss();
        
    }

    async getNeedToLoginAlert(){
        const alert = this._alert.create({
            header: this.translate.instant('ALERTS.AUTH.HEADER', {number: this.number}),
            // subHeader: 'Bla bla bla',
            message: this.translate.instant('ALERTS.AUTH.MSG', {number: this.number}),
            backdropDismiss: true,
            translucent: true,
            buttons: [
                {
                    text: this.translate.instant('ALERTS.AUTH.REGISTER_ACTION', {number: this.number}),
                    handler: () => {
                        localStorage.setItem('tempUrlRedirect', this.router.url);
                        this.router.navigateByUrl('auth/register?returnTo='+this.router.url)
                    }
                },
                {
                    text: this.translate.instant('ALERTS.AUTH.LOGIN_ACTION', {number: this.number}),
                    handler: () => {
                        localStorage.setItem('tempUrlRedirect', this.router.url);
                        this.router.navigateByUrl('auth/login?returnTo='+this.router.url)
                    }
                }
            ],
        }); 
        (await alert).present();
    }

}
