import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface DateCounter {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

@Injectable({
    providedIn: 'root'
})
export class TimeCounterService {

    finishDate: Date;
    currentDateTime: Date;
    
    seconds :number = 0;
    minutes :number = 0;
    hours   :number = 0;
    days    :number = 0;

    DateCounterObs$: Observable<DateCounter>;

    public destroyObsSub: Subject<boolean> = new Subject();

    constructor() {
        this.currentDateTime = new Date();
    }

    initObs(date: Date){

        this.destroyObsSub.next(false);
        this.finishDate = new Date(date);
        this.DateCounterObs$ = Observable.create(
            (observe) => {

                // Start Date timer

                const interVal = setInterval(() => {
                    this.currentDateTime = new Date();
                    this.initDays();
                    const newVals: DateCounter = {
                        days: this.days,
                        hours: this.hours,
                        minutes: this.minutes,
                        seconds: this.seconds
                    }
                    observe.next(newVals);
                }, 1000)

                this.destroyObsSub.subscribe(
                    (res: boolean) => {
                        if(res){
                            setTimeout(() => {
                                clearInterval(interVal);
                                observe.complete();
                            });
                        }
                    }
                )
                
            },
        );

    }

    destroyObs(){
        this.destroyObsSub.next(true);
    }
    

    initDays(){

        let num = (this.finishDate.getTime() - this.currentDateTime.getTime()) ;
        let second2 = 1000;
        let minute2 = second2 * 60;
        let hour2 = minute2 * 60;
        let day2 = hour2 * 24;

        this.days = Math.floor(num / (day2));
        this.hours = Math.floor((num % (day2)) / (hour2))
        this.minutes = Math.floor((num % (hour2)) / (minute2))
        this.seconds = Math.floor((num % (minute2)) / second2);

    }
}