import { Component, Input, OnInit } from '@angular/core';
import { Game } from 'src/app/core/interfaces/game';
import { Master } from 'src/app/core/masters/master.class';
import { NavToGame } from 'src/app/core/store/base/_actions/base.actions';

@Component({
    selector: 'app-block-card-details',
    templateUrl: './block-card-details.component.html',
    styleUrls: ['./block-card-details.component.scss'],
})
export class BlockCardDetailsComponent extends Master implements OnInit {

    @Input() data: Game;

    colors = [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
        'light',
        'medium',
        'dark'
    ];

    color: string;

    constructor() {
        super();
    }

    navToGame(){
        this.store.dispatch(new NavToGame(this.data));
    }

    ngOnInit() {
        this.getRandomColor();
    }

    getRandomColor(){
        this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
    }

}
