import { Action } from '@ngrx/store';
import { Meta, QueryClass } from 'src/app/core/crud/query';
import { Lottery } from 'src/app/core/interfaces/lottery';

export enum LotteryActionTypes {

    /**
     * Loader
     */
    ToggleLoader = '[Lottery] Toggle Loader',

    /**
     * Lottery Actions
     */
    SetLotteryDetails = '[Lottery] Set Lottery Details',
    LoadLotteryFromBackend = '[Lottery] Load Lottery From Backend',
    LotteryWasLoadedFromBackend = '[Lottery] Lottery Was Loaded From Backend',

    /**
     * Lottery Related Data Actions
     */
    ToggleRelatedDataLoader = '[Lottery - Related Data] Toggle Loader',
    ResetRelatedData = '[Lottery - Related Data] Reset Related Data',

    /**
     * Lottery Lotterys Actions
     */
    FormLoading = '[Lottery - Lottery Lottery - Lottery Form] Form Loader',
    LotteryWasSubmited = '[Lottery - Lottery Form - Lottery Form] Lottery Form Was Submitted',
    BuyLotteriesByForm = '[Lottery - Lottery Form - Lottery Form Action] Buuy Lotteires',

    /****************
     * User Lotteries Master List State
     */
     LotteriesToggleWinningsListLoader = '[Lottery - Lotteries List] Toggle List Loader',
     LotteriesLoadWinningsList = '[Lottery - Lotteries List] Load Lottery Lotteries List',
     LotteriesWinningsListWasLoaded = '[Lottery - Lotteries List] Winnings List Was Loaded',
     LotteriesRefreshWinningsList = '[Lottery - Lotteries List] Refresh Winnings List',
     LotteriesListUpdateMeta = '[Lottery - Lotteries] Update Lottery Lotteries List Meta',
    

}

/**
 * User Lotteries
 */
 export class LotteriesToggleWinningsListLoader implements Action {
    readonly type = LotteryActionTypes.LotteriesToggleWinningsListLoader;
    constructor(public payload: boolean){}
}

export class LotteriesLoadWinningsList implements Action {
    readonly type = LotteryActionTypes.LotteriesLoadWinningsList;
    constructor(public payload: QueryClass, public lotteryId: number){}
}

export class LotteriesRefreshWinningsList implements Action {
    readonly type = LotteryActionTypes.LotteriesRefreshWinningsList;
}

export class LotteriesWinningsListWasLoaded implements Action {
    readonly type = LotteryActionTypes.LotteriesWinningsListWasLoaded;
    constructor(public payload: Lottery[]){};
}

export class LotteriesListUpdateMeta implements Action {
    readonly type = LotteryActionTypes.LotteriesListUpdateMeta;
    constructor(public payload: Meta){};
}

/**
 * Form Actions
 */
 export class BuyLotteriesByForm implements Action {
    readonly type = LotteryActionTypes.BuyLotteriesByForm;
    constructor(public payload: any){};
}

export class LotteryWasSubmited implements Action {
    readonly type = LotteryActionTypes.LotteryWasSubmited;
    constructor(public payload: boolean){}
}

export class ResetRelatedData implements Action {
    readonly type = LotteryActionTypes.ResetRelatedData;
}

/**
 * Lottery Lotterys Actions
 */
export class FormLoading implements Action {
    readonly type = LotteryActionTypes.FormLoading;
    constructor(public payload: {status: boolean, withRef: any}){}
}

export class ToggleLoader implements Action {
    readonly type = LotteryActionTypes.ToggleLoader;
    constructor(public payload: boolean){}
}

/**
 * Lottery Actions Start
*/

export class SetLotteryDetails implements Action {
    readonly type = LotteryActionTypes.SetLotteryDetails;
    constructor(public payload: Lottery){}
}

export class LoadLotteryFromBackend implements Action {
    readonly type = LotteryActionTypes.LoadLotteryFromBackend;
    constructor(public payload: number){}
}

export class LotteryWasLoadedFromBackend implements Action {
    readonly type = LotteryActionTypes.LotteryWasLoadedFromBackend;
    constructor(public payload: Lottery){}
}

/**
 * Lottery Actions Finish
*/

/**
 * Lottery Related Data Start
 */

export class ToggleRelatedDataLoader implements Action {
    readonly type = LotteryActionTypes.ToggleRelatedDataLoader;
    constructor(public payload: boolean){}
}
 
/**
 * Lottery Related Data Finish
 */

export type LotteryActions = 
//Loader
ToggleLoader |
//Lottery Module
SetLotteryDetails | LoadLotteryFromBackend | LotteryWasLoadedFromBackend | 
//Lottery Related Data
ToggleRelatedDataLoader | ResetRelatedData | 
//Lottery
FormLoading | BuyLotteriesByForm | LotteryWasSubmited | 
//User lotteries
LotteriesToggleWinningsListLoader |
LotteriesLoadWinningsList |
LotteriesWinningsListWasLoaded |
LotteriesRefreshWinningsList |
LotteriesListUpdateMeta;;