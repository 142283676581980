import { Directive, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
    selector: '[appFadeHeader]'
})
export class FadeHeaderDirective implements OnInit {

    @Input('appFadeHeader') toolbar: any;
    private toolbarHeight = 44;
    private currentPos = 0;
    private opacity = 1;

    constructor(
        private renderer: Renderer2,
        private domCtrl: DomController
    ) {}

    ngOnInit(){
        this.toolbar = this.toolbar.el;
        this.domCtrl.read(() => {
            this.toolbarHeight = this.toolbar.clientHeight;
        })
    }

    @HostListener('ionScroll', ['$event']) onContentScroll(event){
        // if((event.detail.deltaY > 0) && (event.detail.scrollTop < 250)){
        //     const scrollTop = event.detail.scrollTop;
        //     let newPosition = - (scrollTop / 5);
        //     if(newPosition < -this.toolbarHeight){
        //         newPosition = -(this.toolbarHeight);
        //     }
        //     this.currentPos = newPosition;
        //     let newOpacity = 1 - (newPosition / -(this.toolbarHeight));
        //     this.opacity = newOpacity;
        //     this.domCtrl.write(() => {
        //         this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
        //         this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
        //     })
        // }
        // if((event.detail.deltaY > 0) && (event.detail.scrollTop > 250) && (this.currentPos > -this.toolbarHeight)){
        //     let newPosition = this.currentPos - (0.08 * this.toolbarHeight);
        //     let newOpacity = this.opacity - (0.09)
        //     if(newPosition > 0){
        //         newPosition = (0);
        //     }
        //     if(newOpacity > 1){
        //         newOpacity = 1;
        //     }
        //     this.opacity = newOpacity;
        //     this.currentPos = newPosition;
        //     this.domCtrl.write(() => {
        //         this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
        //         this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
        //     })
        // }
        // if(event.detail.deltaY < 0){
        //     let newPosition = this.currentPos + (0.045 * this.toolbarHeight);
        //     let newOpacity = this.opacity + (0.045)
        //     if(newPosition > 0){
        //         newPosition = (0);
        //     }
        //     if(newOpacity > 1){
        //         newOpacity = 1;
        //     }
        //     this.opacity = newOpacity;
        //     this.currentPos = newPosition;
        //     this.domCtrl.write(() => {
        //         this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
        //         this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
        //     })
            
        // }
        const scrollTop = event.detail.scrollTop;
        let newPosition = - (scrollTop / 5);
        if(newPosition < -this.toolbarHeight){
            newPosition = -(this.toolbarHeight);
        }
        let newOpacity = 1 - (newPosition / -(this.toolbarHeight));
        this.domCtrl.write(() => {
            this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
            this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
            this.renderer.setStyle(this.toolbar, 'border', '0px');
        })
        
    }

}
