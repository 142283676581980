import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Effect, ofType, Actions, createEffect } from '@ngrx/effects';
import { BaseActionTypes, ToggleLoader, ToggleDarkMode, ToggleShowCounter, ToggleNumberDisplay, LoadConfig, PaymentMethodsWasLoaded, PaymentPackagesWasLoaded, LoadCategories, CategoriesWasLoaded, ToggleMultiBuyModal, UpdateGuestPushNotitfication, AddIgnoreNoteElement, RemoveIgnoreNoteElement, NavToGame, SetTemStoreData, NavToLottery, StartMining, StopMining, ChangeMiningTime, MiningSucc, ListenToLotteryChanges, UnListenToLotteryChanges, MiningObjectWasChanged, LotteryWasMinedAndAnnounced, ToggleListener, ResetMiningObject } from '../_actions/base.actions';
import { tap, finalize, takeUntil, take } from 'rxjs/operators';
import { BaseService } from '../_services/base.service';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as shajs from 'sha.js';
import { randomIntFromInterval, randomValueFromArray } from 'src/app/core/helpers/helpers';
import { Lottery } from 'src/app/core/interfaces/lottery';
import * as moment from 'moment';
import { SendMiningToBackEndToConfirm } from 'src/app/pages/mining/mine/store/_action/mine.actions';
import { ListenersService } from 'src/app/core/services/listeners.service';
import { Subject } from 'rxjs';
import { ToastController } from '@ionic/angular';


@Injectable()
export class BaseEffects {

    private minsToAddToRemover: number = 0.1;
    
    /**********
     * Mining params
     */
    miningIntval: any;
    timerInterval: any;
    miningObj: Lottery;
    miningOpt: any;
    miningSpeed: number = 1;
    mine: boolean = false;
    miningTime: number;
    startAt: number;
    finishAt: number;
    nonce: number;
    hash: string;
    result: any;
    stopListening: Subject<boolean> = new Subject();

    ToggleListener$ = createEffect(
        () => this.actions$.pipe(
            ofType<ToggleListener>(BaseActionTypes.ToggleListener),
            tap(
                (action: ToggleListener) => {
                    if(action.payload){
                        setTimeout(() => {
                            this.store.dispatch(new ToggleListener(undefined))
                        }, 1200)
                    }
                }
            )
        ),
        {dispatch: false}
    );

    UnListenToLotteryChanges$ = createEffect(
        () => this.actions$.pipe(
            ofType<UnListenToLotteryChanges>(BaseActionTypes.UnListenToLotteryChanges),
            tap(
                (action: UnListenToLotteryChanges) => {
                    // this._listener.unListenToMiningLotteryChanges(action.payload);
                    this.store.dispatch(new ToggleListener({lis: false, event: 'Mining\\LotteryInMiningWasUpdatedEvent', sub: 'LotteryInMiningWasUpdated'+action.payload}))
                }
            )
        ),
        {dispatch: false}
    );

    ListenToLotteryChanges$ = createEffect(
        () => this.actions$.pipe(
            ofType<ListenToLotteryChanges>(BaseActionTypes.ListenToLotteryChanges),
            tap(
                (action: ListenToLotteryChanges) => {
                    this.store.dispatch(new ToggleListener({lis: true, event: 'Mining\\LotteryInMiningWasUpdatedEvent', sub: 'LotteryInMiningWasUpdated'+action.payload, fun: 'UpdateMiningObject'}))
                }
            )
        ),
        {dispatch: false}
    );

    MiningObjectWasChanged$ = createEffect(
        () => this.actions$.pipe(
            ofType<MiningObjectWasChanged>(BaseActionTypes.MiningObjectWasChanged),
            tap(
                (action: MiningObjectWasChanged) => {
                    if(action.payload.mined){
                        this.store.dispatch(new StopMining);
                        this.UpdateMinersThatLotteryWasMined(action.payload);
                        setTimeout(() => {
                            this.store.dispatch(new UnListenToLotteryChanges(action.payload.id));
                            this.store.dispatch(new ResetMiningObject);
                        }, 1500)
                    }
                }
            )
        ),
        {dispatch: false}
    );

    StartMining$ = createEffect(
        () => this.actions$.pipe(
            ofType<StartMining>(BaseActionTypes.StartMining),
            tap(
                (action: StartMining) => {
                    this.startMining(action.payload, action.fast);
                }
            )
        ),
        {dispatch: false}
    ); 

    StopMining$ = createEffect(
        () => this.actions$.pipe(
            ofType<StopMining>(BaseActionTypes.StopMining),
            tap(
                () => {
                    this.mine = false;
                    clearInterval(this.miningIntval);
                    clearInterval(this.timerInterval);
                    setTimeout(() => {
                        // this._listener.unListerToLotteryMinners(this.miningObj.id);
                        this.store.dispatch(new ResetMiningObject);
                    }, 1500)
                }
            )
        ),
        {dispatch: false}
    );

    @Effect({dispatch: false})
    NavToLottery$ = this.actions$.pipe(
        ofType<NavToLottery>(BaseActionTypes.NavToLottery),
        tap(
            (action: NavToLottery) => {
                this.store.dispatch(new SetTemStoreData('lottery', action.payload));
                this._router.navigateByUrl('/app/home/lotteries/lottery/'+action.payload.id+'/'+action.payload.game.translations[action.payload.game.title.text]);
            }
        )
    );

    @Effect({dispatch: false})
    NavToOffer$ = this.actions$.pipe(
        ofType<NavToGame>(BaseActionTypes.NavToGame),
        tap(
            (action: NavToGame) => {
                this.store.dispatch(new SetTemStoreData('game', action.payload));
                this._router.navigateByUrl('/app/home/games/game/'+action.payload.id+'/'+action.payload.translations[action.payload.title.text]);
            }
        )
    );


    @Effect({dispatch: false})
    AddIgnoreNoteElement$ = this.actions$.pipe(
        ofType<AddIgnoreNoteElement>(BaseActionTypes.AddIgnoreNoteElement),
        tap(
            (action: AddIgnoreNoteElement) => {
                setTimeout(() => {
                    this.store.dispatch(new RemoveIgnoreNoteElement(action.payload));                    
                }, (60 * this.minsToAddToRemover * 1000))
            }
        )
    );

    @Effect({dispatch: false})
    UpdateGuestPushNotitfication$ = this.actions$.pipe(
        ofType<UpdateGuestPushNotitfication>(BaseActionTypes.UpdateGuestPushNotitfication),
        tap(
            (action: UpdateGuestPushNotitfication) => {
                this._ser.guestSesstionSub(action.payload).subscribe(
                    (res) => {
                        console.log(res);
                        localStorage.setItem(environment.guestNotesToken, action.payload);
                    },
                    (err) => {
                        console.log(err);
                    }
                )
            }
        )
    );

    @Effect({dispatch: false})
    ToggleDarkMode$ = this.actions$.pipe(
        ofType<ToggleDarkMode>(BaseActionTypes.ToggleDarkMode),
        tap(
            (action: ToggleDarkMode) => {
                if(action.payload){
                    document.body.setAttribute('data-theme', 'dark');
                    localStorage.setItem(environment.darkMode, 'true');
                }
                else{
                    document.body.setAttribute('data-theme', 'light');
                    localStorage.setItem(environment.darkMode, 'false');
                }
            }
        )
    );

    @Effect({dispatch: false})
    ToggleMultiBuyModal$ = this.actions$.pipe(
        ofType<ToggleMultiBuyModal>(BaseActionTypes.ToggleMultiBuyModal),
        tap(
            (action: ToggleMultiBuyModal) => {
                if(action.payload != undefined){
                    setTimeout(() => {
                        this.store.dispatch(new ToggleMultiBuyModal(undefined));
                    }, 1500)
                }
            }
        )
    );

    @Effect({dispatch: false})
    ToggleNumberDisplay$ = this.actions$.pipe(
        ofType<ToggleNumberDisplay>(BaseActionTypes.ToggleNumberDisplay),
        tap(
            (action: ToggleNumberDisplay) => {
                if(action.payload){
                    localStorage.setItem(environment.showLettersNumbers, 'true');
                }
                else{
                    localStorage.setItem(environment.showLettersNumbers, 'false');
                }
            }
        )
    );

    @Effect({dispatch: false})
    ToggleShowCounter$ = this.actions$.pipe(
        ofType<ToggleShowCounter>(BaseActionTypes.ToggleShowCounter),
        tap(
            (action: ToggleShowCounter) => {
                if(action.payload){
                    localStorage.setItem(environment.showCounter, 'true');
                }
                else{
                    localStorage.setItem(environment.showCounter, 'false');
                }
            }
        )
    );

    /**
     * System Config
     */

    @Effect({dispatch: false})
    LoadConfig$ = this.actions$.pipe(
        ofType<LoadConfig>(BaseActionTypes.LoadConfig),
        tap(
            (action: LoadConfig) => {
                this.store.dispatch(new ToggleLoader(true));
                this._ser.loadSysConfig(action.payload).pipe(
                    finalize(() => {
                        this.store.dispatch(new ToggleLoader(false));
                    })
                ).subscribe(
                    (res) => {
                        if(action.payload == 'payment_methods')
                            this.store.dispatch(new PaymentMethodsWasLoaded(res));
                        if(action.payload == 'payment_packages')
                            this.store.dispatch(new PaymentPackagesWasLoaded(res));
                    }
                )
            }
        )
    );

    @Effect({dispatch: false})
    LoadCategories$ = this.actions$.pipe(
        ofType<LoadCategories>(BaseActionTypes.LoadCategories),
        tap(() => {
            this.store.dispatch(new ToggleLoader(true));
            this._ser.loadCategories().pipe(
                finalize(() => {
                    this.store.dispatch(new ToggleLoader(false));
                })
            ).subscribe(
                (res) => {
                    this.store.dispatch(new CategoriesWasLoaded(res.data));
                },
                (err) => {
                }
            )
        })
    );

    constructor(
        private actions$: Actions,
        private _router: Router,
        private _ser: BaseService,
        private store: Store<AppState>,
        private _alert: ToastController
    ){}

    private UpdateMinersThatLotteryWasMined(lottery: Lottery){
        if(Number(localStorage.getItem(environment.userIdStorage)) != lottery.user_id){
            this.presentToast(lottery)
        }
    }

    async presentToast(lottery: Lottery) {
        const url = this._router.url;
        let toast;
        if(url.includes('/mining/mine')){
            toast = await this._alert.create({
                color: 'dark',
                message: 'Your settings have been saved.',
                duration: 10000,
                header: 'Lottery #' + lottery.number + ' was mined.',
                buttons: [
                    {
                        icon: 'close',
                        role: 'close',
                        side: 'end'
                    },
                    {
                        icon: 'key',
                        side: 'start'
                    }
                ]
            });
        }else{
            toast = await this._alert.create({
                color: 'dark',
                message: 'Your settings have been saved.',
                duration: 10000,
                header: 'Lottery #' + lottery.number + ' was mined.',
                buttons: [
                    {
                        text: 'See result',
                        side: 'end',
                        handler: () => {
                            this._router.navigateByUrl('/app/mining/mine/'+lottery.game.title.text+'/'+lottery.game.id+'/'+lottery.id);
                        }
                    },
                    {
                        icon: 'key',
                        side: 'start'
                    }
                ]
            });
        }
        
        toast.present();
    }

    private startMining(data: {object: Lottery, mining_options: any}, fast: number){
        clearInterval(this.miningIntval);
        clearInterval(this.timerInterval);
        this.miningObj = data.object;
        this.miningOpt = data.mining_options;
        this.miningSpeed = fast;
        this.mine = true;
        this.nonce = 1;
        this.miningTime = 0;
        this.miningIntval = setInterval(() => {
            if(this.mine){
                this.result = this.generateRandomData();
                this.hash = this.getHash(this.nonce, this.result);
                if(this.checkHashed(this.hash)){
                    this.finishMining();
                }
                this.nonce = this.nonce + 1;
            }
        }, this.getSpeed());
        this.timerInterval = setInterval(() => {
            this.miningTime = this.miningTime + 1;
            this.store.dispatch(new ChangeMiningTime(this.miningTime))
        }, 1000);
    }

    private getSpeed(): number {
        if(this.miningSpeed === 1){
            return 250;
        }
        if(this.miningSpeed === 2){
            return 100;
        }
        if(this.miningSpeed === 3){
            return 50;
        }
        if(this.miningSpeed === 4){
            return 10;
        }
        if(this.miningSpeed === 5){
            return 1;
        }
        return 250;
    }

    private checkHashed(hashedVal: string): boolean 
    {
        return this.checkDifficultyStageAndValidity(this.miningObj.mining_dif, hashedVal);
    }

    private finishMining(){
        this.mine = false;
        setTimeout(() => {
            this.mine = false;
            clearInterval(this.miningIntval);
            clearInterval(this.timerInterval);
            console.log({hash: this.hash, data: this.result, nonce: this.nonce, time: this.miningTime});
            this.store.dispatch(new MiningSucc({hash: this.hash, data: this.result, nonce: this.nonce, time: this.miningTime}));
            this.store.dispatch(new SendMiningToBackEndToConfirm({hashing: {hash: this.hash, data: this.result, nonce: this.nonce, time: this.miningTime}, object: this.miningObj}));
            this.store.dispatch(new StopMining);
        }, 250)
    }

    private checkDifficultyStageAndValidity(dif: number, hasedVal: string): boolean
    {
        let str = '';
        for(let x = 0; x < dif; x++){
            str = str + '0';
        }
        return hasedVal.slice(0, dif) === str;
    }

    private generateRandomData(): any[]{
        let choosenOpt = [];
        this.miningOpt.forEach((it) => {
            let innerOpt = [];
            let avOpts = it.opts.slice();
            if(it.choose){
                var x = 0;
                for(x; x < it.choose; x++){
                    let index = randomIntFromInterval(0, (avOpts.length - 1));
                    innerOpt.push(avOpts[index]);
                    if(it.type == 'unchosen'){
                        avOpts.splice(index, 1);
                    }
                }
            }else{
                innerOpt.push(randomValueFromArray(avOpts));
            }
            choosenOpt.push({
                options: innerOpt,
                options_key: it.options_key,
                type: it.type,
            })
        })
        return choosenOpt;
    }

    private getHash(num: number, data: any): string{
        let x = JSON.stringify({nonce: num, data: data})
        return shajs('sha256').update(x).digest('hex')
    }

}