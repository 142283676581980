import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    constructor(
        private http: HttpClient
    ) { }

    guestSesstionSub(token: string): Observable<any> {
        return this.http.post(environment.backend+'guest/addNotesToken', {token: token});
    }

    loadSysConfig(key: string): Observable<any>
    {
        return this.http.get(environment.backend+'config/'+key);
    }

    loadCategories(): Observable<any>
    {
        return this.http.get(environment.backend+'load/categories');
    }

}
