import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/app/home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
    },
    {
        path: '404',
        loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule)
    },
    {
        path: 'learning',
        loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
    },
    {
        path: 'app-settings',
        loadChildren: () => import('./pages/app-settings/app-settings.module').then( m => m.AppSettingsPageModule)
    },
    {
        path: 'loudwinner-settings',
        loadChildren: () => import('./pages/profile/settings/settings.module').then( m => m.SettingsPageModule)
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
