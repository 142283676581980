import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { AvenueInjector } from './app/core/masters/avenue.injector';

import 'hammerjs';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
    AvenueInjector.setInjector(moduleRef.injector);
}); 

defineCustomElements(window);
