import { Pipe, PipeTransform } from '@angular/core';
import * as num from 'numeral';
import { environment } from 'src/environments/environment';


/**
 * LoudWinnerNumbers Pipe | lwN
 * @description Transform `numbers` in the way you need using Numeral JS to `numbers` and `currencies` and other `calculations`
 * *You still can use angular default number pipe
 * *You still can use angular default currency pipe
 * @param adder: string | false => What adder do you want? [b, ib, a]
 * @param format: string => e.g '0,0.00'
 * @param addToStr: string => any string to add
 * @param suffix: boolean => Suffix the string? default is prefix
 */
@Pipe({
    name: 'lwN'
})
export class LoudwinnerNumberPipe implements PipeTransform {

    transform(value: number, adder: string | false, format: string = '0,0.00', addToStr: string = null, suffix: boolean = false): string {

        if(adder == 'fSh'){
            return (num(value).format(format+'a')).toUpperCase();
        }
        if(adder == 'fShLw'){
            return environment.appCurrancy + (num(value).format(format+'a')).toUpperCase();
        }

        // num.register('locale', 'fr', {
        //     delimiters: {
        //         thousands: ' ',
        //         decimal: ','
        //     },
        //     abbreviations: {
        //         thousand: 'k',
        //         million: 'S',
        //         billion: 'b',
        //         trillion: 't'
        //     },
        //     ordinal : function (number) {
        //         return number === 1 ? 'er' : 'ème';
        //     },
        //     currency: {
        //         symbol: '€'
        //     }
        // });
        if(adder == false)
            return addToStr ? (suffix ? (num(value).format(format).toUpperCase() + addToStr) : addToStr + num(value).format(format).toUpperCase()) : num(value).format(format).toUpperCase();

        format = localStorage.getItem(environment.showLettersNumbers) 
                && 
                (localStorage.getItem(environment.showLettersNumbers) == 'true') 
                && 
                (!adder || (adder != 'b' && adder != 'ib')) ? 
                `${format}a` : format; 

        if(adder){

            if(adder == '$')
                return num(value).format(`$${format}`).toUpperCase();
            if(adder == 'lw'){}
                return environment.appCurrancy + num(value).format(`${format}`).toUpperCase();
            
        }

        return (num(value).format(format)).toUpperCase();

    }

}
