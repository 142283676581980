import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Config } from '@ionic/angular';
import { Observable } from 'rxjs';
import { LangService } from './core/services/lang.service';
import { SeoService } from './core/services/seo.service';
import { AppState } from './core/store/base';
import { ___DarkMode } from './core/store/base/_selectors/base.selectors';
import { environment } from 'src/environments/environment';
import { ___AuthUserLoggedIn } from './core/store/auth/_selectors/auth.selectors';

import { ToggleDarkMode } from './core/store/base/_actions/base.actions';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    public loginStatus: Observable<boolean>;
    dir: 'rtl' | 'ltr' = 'ltr';
    isDarkMode: Observable<boolean>;

    constructor(
        private _store: Store<AppState>,
        private _translateSer: LangService,
        private config: Config,
        private router: Router,
        private _seo: SeoService,
    ) {
        this.initializeApp();
        this.setTranslations();
        if(localStorage.getItem('notFirstTime')){
            // this.router.navigate(['/app'])
        }else{
            this.router.navigate(['/app-settings'])
        }
    }

    initializeApp() {
        this.isDarkMode = this._store.select(___DarkMode);
        this.isDarkMode.subscribe(
            (res) => {
                if(res)
                    document.body.setAttribute('data-theme', 'dark');
            }
        );
    }

    async ngOnInit() {

        localStorage.setItem(environment.userDeviceMode, this.config.get('mode'));

        this.listenForLoginEvents();

    }

    onDarkModeChange(ev){
        this._store.dispatch(new ToggleDarkMode(ev.detail.checked));
    }

    private setTranslations(){
        this._translateSer.loadTrans();
        this._translateSer.langChangeSubject.subscribe(
            (res) => {
                this.dir = res;
            }
        )
    }

    listenForLoginEvents(){
        this.loginStatus = this._store.select(___AuthUserLoggedIn);
    }

}
