import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeCounterService } from 'src/app/core/helpers/time-counter.service';
import { Game } from 'src/app/core/interfaces/game';
import { Master } from 'src/app/core/masters/master.class';
import { ___ShowCounter } from 'src/app/core/store/base/_selectors/base.selectors';
import * as moment from 'moment';
import { NavToGame } from 'src/app/core/store/base/_actions/base.actions';

@Component({
    selector: 'app-lottery-card-details',
    templateUrl: './lottery-card-details.component.html',
    styleUrls: ['./lottery-card-details.component.scss'],
})
export class LotteryCardDetailsComponent extends Master implements OnInit, OnDestroy {

    @Input() data: Game;
    @Input() counterDefiner: string = 'home';


    showClosingSoon: boolean = false;

    colors = [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
        'light',
        'medium',
        'dark'
    ];

    color: string;

    constructor() {
        super();
    }

    navToGame(){
        this.store.dispatch(new NavToGame(this.data));
    }

    ngOnInit() {
        this.getRandomColor();
    }

    getDate(){
        const lang = this.translate.currentLang;
        moment.locale(lang);
        return (moment(this.data.showing_data.time).format('MMM D, YYYY h:mm a')).toUpperCase();
    }

    getRandomColor(){
        this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
    }

}
