import { Meta } from './query';


/**
 * Map Pagination response and get the meta data of the response
 * @param queryMeta Response data from backend (pagination response)
 */
export function GetMetaData(queryMeta): Meta {

    return {
        from: queryMeta.from,
        to: queryMeta.to,
        current_page: queryMeta.current_page,
        last_page: queryMeta.last_page,
        per_page: queryMeta.per_page,
        total: queryMeta.total
    };
        
}

/**
 * Get the handler you should use for your request
 * @param err Error response
 * @returns 400 | 401 | 403 | 404 | 422 | 500
 */
export function getErrorHandler(err): 400 | 401 | 403 | 404 | 422 | 500{
    switch(err.status){

        case(400):
            return 400;

        case(401):
            return 401;

        case(403):
            return 403;

        case(404):
            return 404;

        case(422):
            return 422;

        case(500):
            return 500;

        default:
            return 400;

    }
}


