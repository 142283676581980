import { BaseState, InitBaseState } from '../_state/base.state';
import { BaseActionTypes, BaseActions } from '../_actions/base.actions';
import { Category } from 'src/app/core/interfaces/category';

import * as _ from 'lodash';
import { removeElemFromArrayByVal } from 'src/app/core/helpers/helpers';

export function BaseReducer(state: BaseState = InitBaseState, action: BaseActions): BaseState{

    switch(action.type){

        /*****
         * Real Time Management
         */
        case BaseActionTypes.ToggleListener: 
            return {
                ...state,
                listenTo: action.payload
            }

        /*********
         * Mining
         */

        case BaseActionTypes.ResetMiningObject:
            return {
                ...state,
                isMining: false,
                miningLotteryId: undefined,
                miningLotteryObject: undefined,
                miningOptions: undefined,
                miningNeededFast: undefined,
                miningLotteryTime: 0
            }

        case BaseActionTypes.MiningObjectWasChanged:
            return {
                ...state,
                miningLotteryObject: action.payload
            }

        case BaseActionTypes.MiningSucc:
            return {
                ...state,
                miningRes: action.payload
            }

        case BaseActionTypes.StartMining:
            return {
                ...state,
                isMining: true,
                miningLotteryId: action.payload.object.id,
                miningLotteryObject: action.payload.object,
                miningOptions: action.payload.mining_options,
                miningNeededFast: action.fast,
                miningLotteryTime: 0
            }

        case BaseActionTypes.StopMining:
            return {
                ...state,
                isMining: false,
            }

        case BaseActionTypes.ChangeMiningTime:
            return {
                ...state,
                miningLotteryTime: action.payload
            }

        case BaseActionTypes.ForceCounterUnsubs: 
            return {
                ...state,
                counterUnsub: action.payload
            }

        /**
         * Tem Store
         */
         case BaseActionTypes.SetTemStoreData: 
            return {
                ...state,
                temStoreData: action.data,
                temStoreType: action.payload
            }

        case BaseActionTypes.DeleteTemStoreData: 
            return {
                ...state,
                temStoreType: undefined,
                temStoreData: undefined
            }


        case BaseActionTypes.RemoveIgnoreNoteElement: 
            return {
                ...state,
                ignoreNotesFrom: removeElemFromArrayByVal(state.ignoreNotesFrom, action.payload)
            }

        case BaseActionTypes.AddIgnoreNoteElement: 
            return {
                ...state,
                ignoreNotesFrom: _.unionBy(state.ignoreNotesFrom, [action.payload])
            }

        case BaseActionTypes.ToggleMultiBuyModal:
            return {
                ...state,
                toggleMultiBuyModal: action.payload
            }

        case BaseActionTypes.AddToOpenedBlockForms:
            return {
                ...state,
                blockFormsWereOpened:  _.unionBy(state.blockFormsWereOpened, [action.payload])
            }

        case BaseActionTypes.AddNewBoughtElementToBlocksBought:
            return {
                ...state,
                blocksNumbersBought:  _.unionBy(state.blocksNumbersBought, [action.payload])
            }

        case BaseActionTypes.CategoriesWasLoaded:
            return {
                ...state,
                categories: getTranslatedTextOfObject(action.payload),
                categoriesWasLoaded: true
            }

        case BaseActionTypes.PaymentMethodsWasLoaded:
            return {
                ...state,
                paymentMethods: action.payload.config
            }

        case BaseActionTypes.PaymentPackagesWasLoaded:
            return {
                ...state,
                paymentPackages: action.payload.config
            }

        case BaseActionTypes.ToggleHideTabs: 
            return {
                ...state,
                hideTabs: action.payload
            }

        case BaseActionTypes.ToggleDarkMode: 
            return {
                ...state,
                darkMode: action.payload
            }

        case BaseActionTypes.ToggleShowCounter:
            return {
                ...state,
                showCounter: action.payload
            }

        case BaseActionTypes.ToggleLoader: 
            return {
                ...state,
                loading: action.payload
            }

        default: 
            return state;

    }

}


function getTranslatedTextOfObject(data: Category[]): Category[] 
{
    let newObj = [];
    data.forEach((it, index) => {
        const translatedText = it.translations[it.title.text]; 
        newObj[index] = {
            ...it,
            translatedText: translatedText
        } 
    });
    return newObj;
}