import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { Game } from "../interfaces/game";
import { User } from "../interfaces/user";

export interface WalletCalcDataSubject {
    canBuyOneAtLeast: boolean;
    canBuyAnotherLottery: boolean;
    winning: number;
    cost: number;
    totalCoinsToUse: number;
    leftCoinsToUse: number;
    wallet: number;
}

@Injectable({
    providedIn: 'root'
})
export class WalletCalcService {

    private userObs$: Observable<User>;
    private formObs$: FormGroup;
    private gameObs$: Observable<Game>;

    public dataSub$: BehaviorSubject<WalletCalcDataSubject> = new BehaviorSubject(null);

    constructor(userObs$: Observable<User>, formObs$: FormGroup, gameObs$: Observable<Game>){
        this.userObs$ = userObs$;
        this.formObs$ = formObs$;
        this.gameObs$ = gameObs$;
    }

    resetMaths(userObs$: Observable<User>, formObs$: FormGroup, gameObs$: Observable<Game>){
        this.userObs$ = userObs$;
        this.formObs$ = formObs$;
        this.gameObs$ = gameObs$;
    }

    makeMath(){
        let wallet: number;
        let cost: number;
        let winning: number;
        let numberOfLotteries: number;
        this.userObs$.subscribe(
            (res) => {
                wallet = res.wallet.balance;
            }
        );
        this.gameObs$.subscribe(
            (res) => {
                cost = res.price;
                // winning = res.cur_win ? res.cur_win : res.min_win;
            }
        );
        numberOfLotteries = (this.formObs$.value['lotteries']).length;
        this.dataSub$.next({
            canBuyOneAtLeast: (wallet - cost) > 0 ? true : false,
            canBuyAnotherLottery: ((wallet - (cost * numberOfLotteries)) > 0) ? true : false,
            winning: winning,
            cost: cost,
            totalCoinsToUse: cost * numberOfLotteries,
            leftCoinsToUse: wallet - (cost * numberOfLotteries),
            wallet: wallet
        });
    }

}