import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';

@Directive({
    selector: '[expandedTextArea]'
})
export class ExpandedTextAreaDirective implements OnInit {

    @HostListener('input', ['$event.target'])
    onInput(textArea:HTMLTextAreaElement):void {
        this.adjust();
    }

    constructor(public element:ElementRef) { }

    ngOnInit(){
        setTimeout(() => this.adjust(), 100);
    }

    adjust():void {
        const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];
        textArea.style.overflow = 'hidden';
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

}
