import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Master } from 'src/app/core/masters/master.class';

@Component({
    selector: 'app-need-more-coins',
    templateUrl: './need-more-coins.component.html',
    styleUrls: ['./need-more-coins.component.scss'],
})
export class NeedMoreCoinsComponent extends Master implements OnInit {

    @Input() showText: boolean = true;

    @Input() size: 'small' | 'default' | 'large' = 'default';
    @Input() fill: 'clear' | 'outline' | 'solid' = 'solid';
    @Input() color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' = 'primary';
    @Input() expand: 'full' | 'block' = 'full';
    @Input() txt: string = 'ADD_MORE_COINS_BUTTON';

    calc: number;

    _transSeciton: string = 'SHARED.';

    constructor(
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
    }

    NavToAddCoins(){
        const curURI = this.router.url;
        localStorage.setItem('tempUrlRedirect', curURI);
        this.router.navigateByUrl('app/profile/wallet/charge-wallet?returnTo='+curURI);
    }

}
