import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lwT'
})
export class LoudwinnerTranslatePipe implements PipeTransform {

    transform(value: {
        text: string;
        translate: boolean;
        default: string;
    }, translations?: any): unknown {

        if(!value.text)
            return value;
        
        if(value.translate && translations)
            return translations[value.text] ?? value.text;
        
        return value.text;

    }

}
