import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Config } from '@ionic/angular';

export const LangOptions: {lang: string; code: string}[] = [
    {
        lang: 'English',
        code: 'en'
    },
    {
        lang: 'Hebrew',
        code: 'he'
    },
    {
        lang: 'Arabic',
        code: 'ar'
    },
] 

@Injectable({
    providedIn: 'root'
})
export class LangService {

    private langs: string[] = ['ar', 'he', 'en'];
    private rtlLans: string[] = ['ar', 'he'];
    private dir: 'rtl' | 'ltr' = 'ltr';

    public langChangeSubject: BehaviorSubject<'rtl' | 'ltr'> = new BehaviorSubject(this.dir);

    constructor(
        private translate: TranslateService,
        @Inject(DOCUMENT) private _document: any,
        private config: Config,
    ) {
        this.loadTrans;
        if(localStorage.getItem(environment.userLangStorage)){
            this.changeLag(localStorage.getItem(environment.userLangStorage));
            this.translate.get('BACK_BUTTON_TEXT').subscribe(values => {
                this.config.set('backButtonText', values);
            });
        }else{
            this.initTranslate();
        }
    }

    initTranslate() {
        // Set the default language for translation strings, and the current language.
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();

        if (browserLang) {
            if (browserLang === 'zh') {
                const browserCultureLang = this.translate.getBrowserCultureLang();

                if (browserCultureLang.match(/-CN|CHS|Hans/i)) {
                    this.translate.use('zh-cmn-Hans');
                } else if (browserCultureLang.match(/-TW|CHT|Hant/i)) {
                    this.translate.use('zh-cmn-Hant');
                }
            } else {
                this.translate.use(this.translate.getBrowserLang());
            }
        } else {
            this.translate.use('en'); // Set your language here
        }

        this.changeLag(this.translate.getBrowserLang());

        this.translate.get('BACK_BUTTON_TEXT').subscribe(values => {
            this.config.set('backButtonText', values);
        });
    }

    loadTrans(){
        this.translate.addLangs(['ar', 'he', 'en']);
    }

    getLanguage(){
        return this.translate.currentLang;
    }

    getDir(): string {
        return this.dir;
    }

    changeLag(lang: string){
        if(this.langs.includes(lang)){
            let rtl: boolean = false;
            if(this.rtlLans.includes(lang))
                rtl = true;
            if(rtl){
                this.dir = 'rtl';
                document.body.setAttribute("dir", "rtl");
                this._document.documentElement.dir = 'rtl';
            }else{
                this.dir = 'ltr';
                document.body.setAttribute("dir", "ltr");
                this._document.documentElement.dir = 'ltr';
            }
            this._document.documentElement.lang = lang;
            this.translate.use(lang);
            this.langChangeSubject.next(this.dir);
            localStorage.setItem(environment.userLangStorage, lang);
        }
    }

}
