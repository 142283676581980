import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AlertsInterface } from '../interfaces/responses';

@Injectable({
    providedIn: 'root'
})
export class ResponseAlertService {


    constructor(
        private _toastSer: ToastController,
        private _router: Router,
    ){}

    public _hadleAlerts(alerts: AlertsInterface){

        if(alerts.toast){
            this._toast(alerts.toast);
        }
        if(alerts.alert){
            this._alert(alerts.alert);
        }
        if(alerts.modal){
            this._modal(alerts.modal);
        }
        
    }

    async _toast(toastObj: any){
        
        if(toastObj.buttons && toastObj.buttons.length > 0){
            toastObj.buttons.forEach((button, index) => {
                if(button.handler && button.handler != null){
                    let newBtn = this.handleToastHandlerButton(button);
                    toastObj.buttons[index] = newBtn;
                }
                return button;
            })
        }
        
        const toast = await this._toastSer.create(toastObj);
        toast.present();
    }

    async _alert(alert: any){

    }

    async _modal(model: any){

    }

    private handleToastHandlerButton(button){
        let handler;
        if(button.handler.action_type == "redirect"){
            let url: string = button.handler.action;
            if(button.handler.with_return){
                const backUrl = this._router.url;
                localStorage.setItem('tempUrlRedirect', backUrl);
                url = url+'?returnTo='+backUrl;
            }
            handler = () => {
                this._router.navigateByUrl(url);
            }
        }
        
        if(handler != null){
            return {
                ...button,
                handler: handler
            }
        }else{
            return button;
        }
    }

}