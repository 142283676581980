import { Action } from "@ngrx/store";
import { ActionNotice } from "../../../../../core/interfaces/action.notice";

export enum ChargeWalletActionTypes {
    ChargeWalletToggleLoader = '[Wallet - ChargeWallet] Charge Wallet Toggle Loader',
    SetActionNotice = '[Wallet - ChargeWallet] Set Wallet Action Notice',
    CreateOrder = '[Wallet - ChargeWallet] Create Order',
    OrderWasCreated = '[Wallet - ChargeWallet] Order Was Created',
    ChargeWalletByOrder = '[Wallet - ChargeWallet] Charge Wallet By Order',
    ResetWalletChargeState = '[Wallet - ChargeWallet] Reset Wallet Charge State',
    OrderWasPaid = '[Wallet - ChargeWallet] Order Was Paid',
}

export class ResetWalletChargeState implements Action{
    readonly type = ChargeWalletActionTypes.ResetWalletChargeState;
}

export class ChargeWalletByOrder implements Action {
    readonly type = ChargeWalletActionTypes.ChargeWalletByOrder;
    constructor(public payload: any){}
}

export class ChargeWalletToggleLoader implements Action {
    readonly type = ChargeWalletActionTypes.ChargeWalletToggleLoader;
    constructor(public payload: boolean){}
}

export class SetActionNotice implements Action {
    readonly type = ChargeWalletActionTypes.SetActionNotice;
    constructor(public payload: ActionNotice){}
}

export class CreateOrder implements Action {
    readonly type = ChargeWalletActionTypes.CreateOrder;
    constructor(public payload:any){}
}

export class OrderWasCreated implements Action {
    readonly type = ChargeWalletActionTypes.OrderWasCreated;
    constructor(public payload: any){}
}

export class OrderWasPaid implements Action {
    readonly type = ChargeWalletActionTypes.OrderWasPaid;
    constructor(public payload: boolean){}
}


export type ChargeWalletActions = ChargeWalletToggleLoader | SetActionNotice | CreateOrder | OrderWasCreated | ChargeWalletByOrder | ResetWalletChargeState | OrderWasPaid;