import * as _ from 'lodash';

/**
 * @description Function that generate random number in range you decide.
 * @param min Minimum number
 * @param max Maximum number
 * @returns number
 */
export function randomIntFromInterval(min, max): number // min and max included 
{ 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomValueFromArray(array): any
{
    return array[Math.floor(Math.random() * array.length)];
}

export function getArrayFromNumber(num: number): any[]
{
    return new Array(num);
}

export function removeElemFromArrayByVal(arr: any[], val: any): any[]{
    return _.remove(_.clone(arr), (value) => {
        return value != val;
    });
}