import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { EmptyDataComponent } from './empty-data/empty-data.component';
import { IonicModule } from '@ionic/angular';
import { TimeCounterService } from '../core/helpers/time-counter.service';
import { BaseService } from '../core/store/base/_services/base.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../core/pipes/safe.pipe';

import { ExpandedTextAreaDirective } from '../core/directives/expanded-text-area.directive';
import { GameTranslatePipe } from '../core/pipes/games-translate.pipe';
import { WalletCalcService } from '../core/helpers/wallet-calc.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NeedToLoginOrRegisterComponent } from './need-to-login-or-register/need-to-login-or-register.component';
import { RouterModule } from '@angular/router';
import { NeedMoreCoinsComponent } from './need-more-coins/need-more-coins.component';
import { LoudwinnerTranslatePipe } from '../core/pipes/loudwinner-translate.pipe';
import { FadeHeaderDirective } from '../core/directives/fade-header.directive';
import { LoudwinnerNumberPipe } from '../core/pipes/loudwinner-number.pipe';
import { LoudwinnerDatePipe } from '../core/pipes/loudwinner-date.pipe';
import { BlockFormNumberCardComponent } from './games/blocks/block-form-number-card/block-form-number-card.component';
import { BlocksSharedService } from './_services/blocks-shared.service';
import { UiUxSharedService } from './_services/ui-ux-shared.service';
import { SkeletonScreenComponent } from './skeleton-screen/skeleton-screen.component';
import { LotteryCountdownCardsComponent } from './lottery-countdown-cards/lottery-countdown-cards.component';
import { GameCardComponent } from '../pages/home/_components/home-page-games-slider/game-card/game-card.component';
import { BlockCardDetailsComponent } from '../pages/home/_components/home-page-games-slider/game-card/block-card-details/block-card-details.component';
import { LotteryCardDetailsComponent } from '../pages/home/_components/home-page-games-slider/game-card/lottery-card-details/lottery-card-details.component';


export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return '**';
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        EmptyDataComponent,
        SkeletonScreenComponent,
        GameTranslatePipe,
        SafePipe,
        ExpandedTextAreaDirective,
        NeedToLoginOrRegisterComponent,
        NeedMoreCoinsComponent,
        LoudwinnerTranslatePipe,
        FadeHeaderDirective,
        LoudwinnerNumberPipe,
        LoudwinnerDatePipe,
        BlockFormNumberCardComponent,
        LotteryCountdownCardsComponent,
        GameCardComponent,
        BlockCardDetailsComponent,
        LotteryCardDetailsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [
        TranslateModule,
        SkeletonScreenComponent,
        EmptyDataComponent,
        GameTranslatePipe,
        SafePipe,
        NeedToLoginOrRegisterComponent,
        NeedMoreCoinsComponent,
        LoudwinnerTranslatePipe,
        FadeHeaderDirective,
        LoudwinnerNumberPipe,
        LoudwinnerDatePipe,
        BlockFormNumberCardComponent,
        LotteryCountdownCardsComponent,
        GameCardComponent,
        BlockCardDetailsComponent,
        LotteryCardDetailsComponent,
        ReactiveFormsModule
    ],
    providers: [
        TimeCounterService,
        BaseService,
        WalletCalcService,
        BlocksSharedService,
        UiUxSharedService
    ],
})
export class SharedModule { }
