import { Action } from '@ngrx/store';
import { Lottery } from 'src/app/core/interfaces/lottery';

export enum MineActionTypes {

    /**
     * Loader
     */
    ToggleLoader = '[Mine] Toggle Loader',

    /**
     * Mine Actions
     */
    SetMineDetails = '[Mine] Set Mine Details',
    LoadMineFromBackend = '[Mine] Load Mine From Backend',
    MineWasLoadedFromBackend = '[Mine] Mine Was Loaded From Backend',
    ResetData = '[Mine] Reset Data',

    /**
     * Mine Related Data Actions
     */
    ToggleRelatedDataLoader = '[Mine - Related Data] Toggle Loader',

    /**
     * Related actions
     */
    ToggleRelatedActionLoader = '[Mine - Related Action] Toggle Loader',
    AddNewMiner = '[Mine - Related Action] Add New Miner',
    SendMiningToBackEndToConfirm = '[Mine - Related Action] Send Mining to Backend To Config You Hash',
    UpdateMiningLottery = '[Mine - Mining] Update Mining Lottery'

}

/**
 * Mining Actions
 */
export class UpdateMiningLottery implements Action {
    readonly type = MineActionTypes.UpdateMiningLottery;
    constructor(public payload: Lottery){}
}

export class AddNewMiner implements Action {
    readonly type = MineActionTypes.AddNewMiner;
    constructor(public payload: number){}
}

export class SendMiningToBackEndToConfirm implements Action {
    readonly type = MineActionTypes.SendMiningToBackEndToConfirm;
    constructor(public payload: any){}
}



export class ToggleLoader implements Action {
    readonly type = MineActionTypes.ToggleLoader;
    constructor(public payload: boolean){}
}

export class ToggleRelatedActionLoader implements Action {
    readonly type = MineActionTypes.ToggleRelatedActionLoader;
    constructor(public payload: boolean){}
}

/**
 * Mine Actions Start
*/

export class SetMineDetails implements Action {
    readonly type = MineActionTypes.SetMineDetails;
    constructor(public payload: any){}
}

export class LoadMineFromBackend implements Action {
    readonly type = MineActionTypes.LoadMineFromBackend;
    constructor(public payload: number){}
}

export class MineWasLoadedFromBackend implements Action {
    readonly type = MineActionTypes.MineWasLoadedFromBackend;
    constructor(public payload: any){}
}

export class ResetData implements Action {
    readonly type = MineActionTypes.ResetData;
}

/**
 * Mine Actions Finish
*/



/**
 * Mine Related Data Start
 */

export class ToggleRelatedDataLoader implements Action {
    readonly type = MineActionTypes.ToggleRelatedDataLoader;
    constructor(public payload: boolean){}
}
 
/**
 * Mine Related Data Finish
 */

export type MineActions = 
//Mining
AddNewMiner | SendMiningToBackEndToConfirm | UpdateMiningLottery | 
//Loader
ToggleLoader |
//Mine Module
SetMineDetails | LoadMineFromBackend | MineWasLoadedFromBackend | ResetData | 
//Mine Related Data
ToggleRelatedDataLoader | 
//Related Action
ToggleRelatedActionLoader;