import { AuthActionTypes, AuthActions } from '../_actions/auth.actions';
import { AuthState, InitAuthState } from '../_states/auth.state';

export function AuthReducer(state: AuthState = InitAuthState, action: AuthActions): AuthState{
    switch (action.type){

        case AuthActionTypes.UserObjectChanged: {            
            return {
                ...state,
                user: action.payload.userObj,
                activeGames: action.payload.activeGames,
                notesCount: action.payload.notesCount,
                subs: action.payload.subs
            }
        }

        /**
         * Master
         */

        case AuthActionTypes.AuthToggleLoader:{
            return {
                ...state,
                loading:action.payload
            }
        }

        /**
         * Auth Basics
         */

        case AuthActionTypes.UserWasLoggedIn: {
            return {
                ...state,
                accessToken: action.payload.accessToken,
                tokenType: action.payload.tokenType,
                user: action.payload.user,
                isLoggedIn: true,
                notesCount: action.payload.notesCount,
                activeGames: action.payload.activeGames,
                subs: action.payload.subs
            }
        }

        case AuthActionTypes.Logout: {
            return InitAuthState;
        }

        /**
         * Auth Action
         */
        
        case AuthActionTypes.ToggleAuthActionLoader: {
            return {
                ...state,
                authAction: {
                    ...state.authAction,
                    ActionLoading: action.payload
                }
            }
        }

        case AuthActionTypes.SetActionNotice: {
            return {
                ...state,
                authAction: {
                    ...state.authAction,
                    ActionNotice: action.payload
                }
            }
        }

        /**
         * Profile
         */
        
        case AuthActionTypes.UserWasUpdated: {
            return {
                ...state,
                user: action.payload
            }
        }

        default: 
            return state;

    }
}