import { Injectable } from '@angular/core';

import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';

import { Capacitor } from '@capacitor/core';
import { RegisterUserNotificationToken } from '../store/auth/_actions/auth.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/base';
import { Observable } from 'rxjs';
import { ___AuthUserLoggedIn } from '../store/auth/_selectors/auth.selectors';
import { UpdateGuestPushNotitfication } from '../store/base/_actions/base.actions';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private loggedIn$: Observable<boolean>;

    constructor(
        private _store: Store<AppState>
    ){

        this.loggedIn$ = this._store.pipe(
            select(___AuthUserLoggedIn)
        );
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            this.initPushNotifications();
        };

    }

    initPushNotifications(){

        if (!Capacitor.isPluginAvailable('PushNotifications')) {
            return;
        }

        if(!(localStorage.getItem(environment.userNotesToken)) && !localStorage.getItem(environment.guestNotesToken)){
            this.askPermission();
        }

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                this.handleNotification(notification);
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                alert('Push action performed: ' + JSON.stringify(notification));
            }
        );

    }

    private askPermission(){
        
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // Show some error
            }
        });

        PushNotifications.addListener('registration',
            (token: Token) => {
                alert('Push registration success, token: ' + token.value);
                this.loggedIn$.subscribe(
                    (res) => {
                        if(res) {
                            if(!(localStorage.getItem(environment.userNotesToken)))
                                this._store.dispatch(new RegisterUserNotificationToken(token.value));
                        }else{
                            if(!(localStorage.getItem(environment.guestNotesToken)))
                                this._store.dispatch(new UpdateGuestPushNotitfication(token.value));
                        }
                    }
                )
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        
    }

    private handleNotification(notification: PushNotificationSchema){
        alert('Handle Push of: ' + JSON.stringify(notification))
        alert(JSON.stringify(notification.data))
    }

}
