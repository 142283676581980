import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import Pusher from 'pusher-js';
import { Observable, Subject } from 'rxjs';
import { OrderWasPaid } from 'src/app/pages/profile/wallet/_store/_actions/charge-wallet.actions';
import { environment } from 'src/environments/environment';
import { Lottery } from '../interfaces/lottery';
import { Order } from '../interfaces/order';
import { User } from '../interfaces/user';
import { UserObjectChanged } from '../store/auth/_actions/auth.actions';
import { ___AuthUserLoggedIn } from '../store/auth/_selectors/auth.selectors';
import { AppState } from '../store/base';
import { MiningObjectWasChanged } from '../store/base/_actions/base.actions';

@Injectable({
    providedIn: 'root'
})
export class ListenersService {

    public pusher: Pusher;
    private basicInited: boolean = false;
    private loggedIn$: Observable<boolean>;
    private defaultEventPath: string = 'App\\Events\\API\\';
    private orderListener;
    private newLotteriesToMine: any;
    public LotteryObjectWasUpdated: Subject<Lottery> = new Subject;

    private listeners: any[] = [];

    constructor(
        private _store: Store<AppState>,
        private _toast: ToastController,
        private _router: Router,
    ) {
        this.loggedIn$ = this._store.pipe(
            select(___AuthUserLoggedIn)
        );
    }

    public listenTo(lis: {event: string, sub: string, fun: string}){
        if(!this.listeners[lis.event]){
            this.listeners[lis.event] = this.pusher.subscribe(lis.sub);
            this.listeners[lis.event].bind(this.defaultEventPath+lis.event, (data) => {
                const fun = lis.fun;
                this[fun](data);
            });
        }
    }

    private UpdateMiningObject(data: any){
        console.log(data);
        this._store.dispatch(new MiningObjectWasChanged(data.lottery));
    }

    public unListenTo(lis: {event: string, sub: string}){
        console.log(this.pusher);
        console.log(this.listeners);
        if(this.listeners[lis.event]){
            this.listeners[lis.event].unsubscribe();
            this.listeners[lis.event].unbind(this.defaultEventPath+lis.event);
            this.listeners[lis.event] = undefined;
            this.pusher.unsubscribe(lis.sub);
        }
        console.log(this.pusher);
        console.log(this.listeners);
    }

    public init(init: boolean = false){
        this.loggedIn$.subscribe(
            (res) => {
                if(res && localStorage.getItem(environment.authTokenKey) && !this.pusher){
                    this.pusher = new Pusher('LoudWinnerKey', {
                        cluster: 'mt1',
                        wsHost: environment.backendUrl,
                        wsPort: 6001,
                        forceTLS: false,
                        disableStats: true,
                        auth: {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem(environment.authTokenKey)
                            }
                        }
                    });
                    // this.listenToUserObject();
                }else{
                    if(!this.pusher){
                        this.pusher = new Pusher('LoudWinnerKey', {
                            cluster: 'mt1',
                            wsHost: environment.backendUrl,
                            wsPort: 6001,
                            forceTLS: false,
                            disableStats: true
                        });
                    }
                }
                if(init)
                    this.InitBasicListeners();
            }
        )
    }

    public InitBasicListeners(){
        if(!this.basicInited){
            this.newLotteriesToMine = this.pusher.subscribe('NewLotteriesToMine');
            this.newLotteriesToMine.bind(this.defaultEventPath+'Mining\\NewLotteriesToMineEvent', () => {
                this.presentNewLotteriesToast();
            });
            this.basicInited = true;
        }
    }
    
    async presentNewLotteriesToast() {
        const toast = await this._toast.create({
            animated: true,
            color: 'dark',
            duration: 10000,
            header: 'New lotteries seeking miners',
            message: 'Hurry up, and generate your profit from mining fees.',
            position: 'top',
            buttons: [
                {
                    text: 'Mine..',
                    side: 'end',
                    handler: () => {
                        this._router.navigateByUrl('/app/mining');
                    }
                }
            ]
        });
        toast.present();
    }

    public listerToOrder(order: Order) {
        this.orderListener = this.pusher.subscribe('Order'+order.id);
        this.orderListener.bind(this.defaultEventPath+'Orders\\OrderUpdatedEvent', (order: Order) => {
            if(order.status != 'pending'){
                this._store.dispatch(new OrderWasPaid(true));
                this.orderListener.unsubscribe();
                this.pusher.unsubscribe('Order'+order.id);
            }else{
            }
        }) 
    }

    private listenToUserObject(){
        if(localStorage.getItem(environment.userIdStorage)){
            const userObj = this.pusher.subscribe('UserObject'+localStorage.getItem(environment.userIdStorage));
            userObj
            .bind(this.defaultEventPath+'Auth\\UserObjectWasUpdatedEvent', (res: {userObj: User, notesCount: number, activeGames: number, subs: number[]}) => {
                this._store.dispatch(new UserObjectChanged(res));
                alert('user object => ' + JSON.stringify(res));
            }); 
        }
    }
    
}
