import { createSelector } from '@ngrx/store';

import { AuthState } from '../_states/auth.state';

export const SelectAuthState = state => state.auth;

/**
 * Master
 */

export const ___AuthLoading = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.loading
);

/**
 * Auth
 */

export const ___AuthUser = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.user
);

export const ___AuthUserLoggedIn = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.isLoggedIn
);

export const ___UserNotificationsCount = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.notesCount
);

export const ___UserActiveGamesCount = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.activeGames
);

export const ___UserSubs = createSelector(
    SelectAuthState,
    (auth: AuthState) => auth.subs
);

/**
 * Auth Action
 */
export const ___ActionLoading = createSelector(
    SelectAuthState,
    (ModuleState: AuthState) => ModuleState.authAction.ActionLoading
);

export const ___ActionSubmited = createSelector(
    SelectAuthState,
    (ModuleState: AuthState) => ModuleState.authAction.ModelWasAdded
);

export const ___ActionModel = createSelector(
    SelectAuthState,
    (ModuleState: AuthState) => ModuleState.authAction.ModelValues
);

export const ___ActionNotice = createSelector(
    SelectAuthState,
    (ModuleState: AuthState) => ModuleState.authAction.ActionNotice
);

