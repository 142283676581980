import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InterceptService {

    intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>>{

        if(request.url.includes('loudwinner') || request.url.includes('localhost')){
            if(this.checkIfAuthenticated()){
                request = this.addAuthorization(request);
            }
            
            request = this.addIPAdress(request);
            request = this.addConfigOptions(request);
            request = this.addPlatform(request);
            request = this.addLang(request);

        }

        // console.log(request);
        

        return next.handle(request).pipe(
            tap(
                event => {
                    console.log(event);
                },
                err => {
                    console.log(err);
                }
            )
        )

    }

    addPlatform(request: HttpRequest<any>): HttpRequest<any>{
        const mode = localStorage.getItem(environment.userDeviceMode);
        return request.clone({
            setParams: {
                uM: mode
            }
        });
    }

    addConfigOptions(request: HttpRequest<any>): HttpRequest<any>{
        let obj: any = localStorage.getItem(environment.userIpConfig);
        if(obj != null){
            obj = JSON.parse(obj);
            request = request.clone({
                setParams: {
                    uC: obj.country_code
                }
            })
        }
        return request;
    }

    addIPAdress(request: HttpRequest<any>): HttpRequest<any>{
        let ip = localStorage.getItem(environment.userIpAddress);
        if(ip != null){
            return request.clone({
                setParams: {
                    uIp: localStorage.getItem(environment.userIpAddress)
                }
            })
        }
        return request;
    }

    checkIfAuthenticated(): boolean{
		let token = localStorage.getItem(environment.authTokenKey);
		if(token != null){
			return true;
		}else{
			return false;
		}
	}

	addAuthorization(req: HttpRequest<any>): HttpRequest<any>{
		let token = localStorage.getItem(environment.authTokenKey);
		req = req.clone({
				setHeaders: {
					Authorization: 'Bearer ' + token
                },
                setParams: {
                    uId: localStorage.getItem(environment.userIdStorage)
                }
			});
		return req;
	}

    addLang(req: HttpRequest<any>): HttpRequest<any>{
        let lang = localStorage.getItem(environment.userLangStorage) ? localStorage.getItem(environment.userLangStorage) : 'en';
        req = req.clone({
            setHeaders: {
                localization: lang
            } 
        });
        return req;
    }

}
