import { Action } from '@ngrx/store';
import { Lottery } from 'src/app/core/interfaces/lottery';
import { Game } from '../../../interfaces/game';

export enum BaseActionTypes {

    /**
     * Loader
     */
    ToggleLoader = '[Base] Toggle Loader',

    /**
     * App settings actions
     */
    ToggleDarkMode = '[Base - Settings] Toggle Dark Mode',
    ToggleShowCounter = '[Base - Settings] Toggle Show Counter',
    ToggleNumberDisplay = '[Base - Settings] Toggle Number Display',

    /**
     * Config
     */
    LoadConfig = '[Base - Config] Load System Config',
    PaymentMethodsWasLoaded = '[Base - Config] Payment Methods Was Loaded',
    PaymentPackagesWasLoaded = '[Base - Config] Payment Packages Was Loaded',

    LoadCategories = '[Base - Categories] Load Categories',
    CategoriesWasLoaded = '[Base - Categories] Categories Was Loaded',

    ToggleHideTabs = '[Base - Settings] Toggle Hide Tabs',

    /**
     * Shared Actions
     */
    AddNewBoughtElementToBlocksBought = '[Base - Shared - Blocks] Add New Element To Blocks Shared Bought',
    AddToOpenedBlockForms = '[Base - Shared - Blocks - Forms] Add New Element To Opened Block Forms',

    /**
     * UI FUNCTIONs
     */
    ToggleMultiBuyModal = '[Shared - UI] Toggle Multi Buy Modal',

    /**
     * Guest Sesstion management
     */
    UpdateGuestPushNotitfication = '[Base - Guest] Update Guest Sesstion Push Notifications',

    /**
     * Notes
     */
    AddIgnoreNoteElement = '[Base - Notes] Add Ignore Note Element',
    RemoveIgnoreNoteElement = '[Base - Notes] Remove Ignore Note Element',

    //Temp Store Actions
    SetTemStoreData = '[Base - Temp Store] Set Tem Store Data And Type',
    DeleteTemStoreData = '[Base - Temp Store] Delete Tem Store Data',

    NavToGame = '[Base - Game] Open Game Page',
    NavToLottery = '[Base - Lottery] Open Lottery Page',

    ForceCounterUnsubs = '[Base - Shared - Lotteries] Force Counter Unsubscribe',


    /**********
     * Mining
     */
    StartMining = '[Base - Mining - Start Mining]',
    StopMining = '[Base - Mining - Stop Mining]',
    ChangeMiningTime = '[Base - Mining - Change Mining Time]',
    MiningSucc = '[Base - Mining] Mining Succ',
    ListenToLotteryChanges = '[Base - Mining] Lister To Mining Lottery Changes',
    UnListenToLotteryChanges = '[Base - Mining] UnLister And Cancel To Mining Lottery Changes',
    MiningObjectWasChanged = '[Base - Mining] Mining Lottery Was Changed And Updated',
    LotteryWasMinedAndAnnounced = '[Base - Mining] Lottery Was Mined And Announced',
    ResetMiningObject = '[Base - Mining] Reset Mining Object',

    /**
     * Real Time Listener
     */
    ToggleListener = '[Base - Listener Real Time] Toggle listener',

}

/**
 * Real Time Listener
 */
export class ToggleListener implements Action {
    readonly type = BaseActionTypes.ToggleListener;
    constructor(public payload: any){}
}

/**
 * Mining
 */

export class ResetMiningObject implements Action {
    readonly type = BaseActionTypes.ResetMiningObject;
}

export class MiningObjectWasChanged implements Action {
    readonly type = BaseActionTypes.MiningObjectWasChanged;
    constructor(public payload: Lottery){}
}

export class LotteryWasMinedAndAnnounced implements Action {
    readonly type = BaseActionTypes.LotteryWasMinedAndAnnounced;
    constructor(public payload: boolean){}
}

export class UnListenToLotteryChanges implements Action {
    readonly type = BaseActionTypes.UnListenToLotteryChanges;
    constructor(public payload: number){}
}

export class ListenToLotteryChanges implements Action {
    readonly type = BaseActionTypes.ListenToLotteryChanges;
    constructor(public payload: number){}
}

export class StartMining implements Action {
    readonly type = BaseActionTypes.StartMining;
    constructor(public payload: {
        object: Lottery,
        mining_options: any
    }, public fast: number){}
}

export class MiningSucc implements Action {
    readonly type = BaseActionTypes.MiningSucc;
    constructor(public payload: any){}
}

export class StopMining implements Action {
    readonly type = BaseActionTypes.StopMining;
}

export class ChangeMiningTime implements Action {
    readonly type = BaseActionTypes.ChangeMiningTime;
    constructor(public payload: number){}
}

/*
*/


export class ForceCounterUnsubs implements Action {
    readonly type = BaseActionTypes.ForceCounterUnsubs;
    constructor(public payload: string){}
}

//Temp Store Actions

export class SetTemStoreData implements Action {
    readonly type = BaseActionTypes.SetTemStoreData;
    constructor(public payload: 'game' | 'lottery', public data: Game | Lottery){}
}

export class DeleteTemStoreData implements Action {
    readonly type = BaseActionTypes.DeleteTemStoreData;
}

export class NavToGame implements Action {
    readonly type = BaseActionTypes.NavToGame;
    constructor(public payload: Game){}
}

export class NavToLottery implements Action {
    readonly type = BaseActionTypes.NavToLottery;
    constructor(public payload: Lottery){}
}

/**
 * 
 */

export class AddIgnoreNoteElement implements Action {
    readonly type = BaseActionTypes.AddIgnoreNoteElement;
    constructor(public payload: string){}
}

export class RemoveIgnoreNoteElement implements Action {
    readonly type = BaseActionTypes.RemoveIgnoreNoteElement;
    constructor(public payload: string){}
}

/**
 * Guest Sesstion management
 */
export class UpdateGuestPushNotitfication implements Action {
    readonly type = BaseActionTypes.UpdateGuestPushNotitfication;
    constructor(public payload: string){}
}

/**
 * UI UX
 */
export class ToggleMultiBuyModal implements Action {
    readonly type = BaseActionTypes.ToggleMultiBuyModal;
    constructor(public payload: string | undefined){}
}

/**
 * Shared Actions
 */
export class AddNewBoughtElementToBlocksBought implements Action {
    readonly type = BaseActionTypes.AddNewBoughtElementToBlocksBought;
    constructor(public payload: string){}
}

export class AddToOpenedBlockForms implements Action {
    readonly type = BaseActionTypes.AddToOpenedBlockForms;
    constructor(public payload: number){}
}

/**
 * Base Loaders
 */
export class LoadCategories implements Action{
    readonly type = BaseActionTypes.LoadCategories;
}

export class CategoriesWasLoaded implements Action {
    readonly type = BaseActionTypes.CategoriesWasLoaded;
    constructor(public payload: any){}
}

/**
 * App Settings
 */
export class ToggleDarkMode implements Action {
    readonly type = BaseActionTypes.ToggleDarkMode;
    constructor(public payload: boolean){}
}

export class ToggleNumberDisplay implements Action {
    readonly type = BaseActionTypes.ToggleNumberDisplay;
    constructor(public payload: boolean){}
}

export class ToggleShowCounter implements Action {
    readonly type = BaseActionTypes.ToggleShowCounter;
    constructor(public payload: boolean){}
}

export class ToggleHideTabs implements Action {
    readonly type = BaseActionTypes.ToggleHideTabs;
    constructor(public payload: boolean){}
}

/**
 * System Config
 */
 export class LoadConfig implements Action {
    readonly type = BaseActionTypes.LoadConfig;
    constructor(public payload: string){}
}

export class PaymentMethodsWasLoaded implements Action {
    readonly type = BaseActionTypes.PaymentMethodsWasLoaded;
    constructor(public payload: any){}
}

export class PaymentPackagesWasLoaded implements Action {
    readonly type = BaseActionTypes.PaymentPackagesWasLoaded;
    constructor(public payload: any){}
}

/**
 * Masters Start
 */
export class ToggleLoader implements Action {
    readonly type = BaseActionTypes.ToggleLoader;
    constructor(public payload: boolean){}
}

 /**
 * Temp Store Finish
 */



export type BaseActions = 
/*********
 * Mining
 */
ResetMiningObject | StartMining | StopMining | ChangeMiningTime | MiningSucc | ListenToLotteryChanges | UnListenToLotteryChanges | LotteryWasMinedAndAnnounced | MiningObjectWasChanged | 
LoadCategories | CategoriesWasLoaded |
ToggleLoader | 
ToggleDarkMode | ToggleShowCounter | ToggleNumberDisplay | ToggleHideTabs |
LoadConfig | PaymentMethodsWasLoaded | PaymentPackagesWasLoaded | 
AddNewBoughtElementToBlocksBought | AddToOpenedBlockForms |  
ToggleMultiBuyModal | 
UpdateGuestPushNotitfication | 
AddIgnoreNoteElement | RemoveIgnoreNoteElement |
SetTemStoreData | DeleteTemStoreData | NavToGame | NavToLottery | 
ForceCounterUnsubs | 
ToggleListener;