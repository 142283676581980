import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TimeCounterService } from 'src/app/core/helpers/time-counter.service';
import { Game } from 'src/app/core/interfaces/game';
import { Lottery } from 'src/app/core/interfaces/lottery';
import { Master } from 'src/app/core/masters/master.class';
import { ___CounterUnsub, ___ShowCounter } from 'src/app/core/store/base/_selectors/base.selectors';
import { LoadLotteryFromBackend } from 'src/app/pages/home/lotteries/lottery/store/_action/lottery.actions';
import * as moment from 'moment';

@Component({
    selector: 'app-lottery-countdown-cards',
    templateUrl: './lottery-countdown-cards.component.html',
    styleUrls: ['./lottery-countdown-cards.component.scss'],
})
export class LotteryCountdownCardsComponent extends Master implements OnInit, OnDestroy, AfterViewInit{

    @Input() lottery: Lottery;
    @Input() game: Game;
    @Input() big: boolean = false;
    @Input() unSubStr: string;
    @Input() showActionLine: boolean = true;

    showCounters: Observable<boolean>;

    public _counterSer: TimeCounterService;

    showAnimations: string[] = [
        'puffIn',
        'vanishIn',
    ];

    show: string[] = [];

    finished: boolean = false;

    constructor() {
        super();
    }

    ngOnInit() {
        if(this.big){
            this.pickRandomColorStyle(4);
        }
        this.getAnimations(4);
        if(moment().diff(this.lottery.time) > 0){
            this.finished = true;
        }
    }

    loadAgain(){
        this.store.dispatch(new LoadLotteryFromBackend(this.lottery.id));
    }

    getAnimations(num: number = 1){
        for(let i = 0; i < num; i++){
            this.show.push(this.showAnimations[Math.floor(Math.random() * this.showAnimations.length)]);
        }
    }

    ngAfterViewInit(){
        if(!this.finished){
            setTimeout(() => {
                this.showCounters = this.store.pipe(
                    select(___ShowCounter),
                    takeUntil(this._onDestroy)
                );
                this.store.pipe(
                    select(___CounterUnsub),
                ).subscribe(
                    (res) => {
                        if(res){
                            if(res == this.unSubStr){
                                this.showCounters.pipe(
                                    take(1)
                                ).subscribe(
                                    (res) => {
                                        if(res){
                                            this._counterSer = new TimeCounterService();
                                            this._counterSer.initObs(this.lottery.time);
                                        }
                                    }
                                );
                            }else{
                                this.ngOnDestroy();
                            } 
                        }else{
                            this.ngOnDestroy();
                        }
                        
                    }
                );
                
            })
        }
    }

    ngOnDestroy(){
        
        this._onDestroy.next();
        if(this._counterSer)
            this._counterSer.destroyObs();
        
    }

}
